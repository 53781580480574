import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  sendLoginOTP,
  setLoginModelVisibility,
  verifyLogin,
} from "../../store/slices/auth.slice";
import { AccountMenu } from "./Account";
//import { useGoogleLogin } from "./GoogleLoginContext";
import ForgotPasswordDialog from "./ForgotPassword";
import SignUpDialog from "./SignUp";
import Loader from "../Loader";
//import { jwtDecode } from "jwt-decode";
//import { JwtPayload } from "./Helper";

const LoginDialog: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  //const { setGoogleLoginState } = useGoogleLogin();

  const showLoginModel: boolean = useSelector(
    (state: RootState) => state.auth.otp.showLogin
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [isSignUp, setSignUpDialog] = useState(false);
  const [error, setError] = useState(false);

  const loading = useSelector((state: RootState) => state.auth.loading);

  const handleClose = useCallback(() => {
    dispatch(setLoginModelVisibility(false));
  }, [dispatch]);

  const handleVerifyEmail = (emailId: string) => {
    dispatch(sendLoginOTP(emailId));
  };

  const handleLogin = async () => {
    const result = await dispatch(verifyLogin({ email, password }));
    if (result && result?.payload?.success) {
      setEmail("");
      setPassword("");
      // const googleUser = jwtDecode<JwtPayload>(result.payload.data.token);
      // setGoogleLoginState({
      //   isAuthenticated: true,
      //   userData: {
      //     name: `${googleUser.first_name} ${googleUser.last_name}`,
      //     emailId: googleUser.email,
      //     firstName: googleUser.first_name || "",
      //     lastName: googleUser.last_name || "",
      //   },
      // });
    } else {
      setError(true);
    }
  };

  const handleForgotPassword = () => {
    // Handle forgot password logic here
    console.log("Forgot password clicked");
    setForgotPassword(true);
  };

  const handleCreateAccount = () => {
    // Handle create account logic here
    console.log("Create account clicked");
    setSignUpDialog(true);
  };

  return (
    <>
      {!isForgotPassword && !isSignUp && (
        <Dialog
          open={showLoginModel}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle variant="h5" align="center">
            Welcome to Bidzbay
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 3,
            }}
          >
            {error && (
              <Alert severity="error">
                There was an error, please try again
              </Alert>
            )}
            {loading && <Loader />}
            {!loading && (
              <>
                <Box sx={{ width: "100%", marginTop: 2 }}>
                  <Typography variant="body1" gutterBottom sx={{ ml: 5 }}>
                    <AccountMenu handleClose={handleClose} />
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ marginTop: 2 }}
                  >
                    Or login with
                  </Typography>
                </Box>

                <>
                  <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                      marginBottom: "16px",
                      width: "300px",
                      height: "40px",
                    }}
                  />
                  <TextField
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                      marginBottom: "16px",
                      width: "300px",
                      height: "40px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleLogin()}
                    sx={{
                      marginTop: "16px",
                      width: "300px",
                      height: "40px",
                    }}
                  >
                    Login
                  </Button>
                  <Box sx={{ marginTop: "16px", width: "100%" }}>
                    <Typography
                      variant="body2"
                      color="primary"
                      onClick={handleForgotPassword}
                      sx={{
                        cursor: "pointer",
                        textAlign: "left",
                        display: "inline-block",
                        ml: -10,
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      onClick={handleCreateAccount}
                      sx={{
                        cursor: "pointer",
                        textAlign: "right",
                        display: "inline-block",
                        float: "right",
                        mr: 4,
                      }}
                    >
                      Create Account
                    </Typography>
                  </Box>
                </>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      {isForgotPassword && (
        <ForgotPasswordDialog
          handleVerifyEmail={handleVerifyEmail}
          setForgotPassword={setForgotPassword}
          closeLoginDialog={handleClose}
        />
      )}
      {isSignUp && (
        <SignUpDialog
          handleVerifyEmail={handleVerifyEmail}
          setSignUpDialog={setSignUpDialog}
          closeLoginDialog={handleClose}
        />
      )}
    </>
  );
};

export default LoginDialog;
