import * as React from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ProductType } from "../product.type";
import { Bid } from "../BidCard";
import { COUNTRY_CURRENCY, currencySymbols } from "../../utils/constants";
import CurrencyPrice from "../../common/PriceWithCurrency";

interface BidModalProps {
  product: ProductType;
  open: boolean;
  onClose: () => void;
  handleAmountChange: (e: any) => void;
  setCurrency: (currency: any) => void;
  handleSubmit: () => void;

  topBid: number;
  currency: string;
  myBid: Bid | null;
  error: string;
}

const BidDialog: React.FC<BidModalProps> = ({
  open = false,
  onClose,
  topBid,
  product,
  myBid,
  currency = "USD",
  error,
  handleAmountChange,
  handleSubmit,
  setCurrency,
}) => {
  return (
    <Dialog
      sx={{ p: 2, lineHeight: 5 }}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
        Place Your Bid
      </DialogTitle>
      <DialogContent>
        {topBid > 0 && (
          <Typography variant="subtitle1">
            Top Bidding amount is&nbsp;&nbsp;
            <Typography variant="h6" color="textPrimary" display="inline-flex">
              <CurrencyPrice price={topBid} currency={currency || "USD"} />
            </Typography>
          </Typography>
        )}
        <Typography variant="subtitle1">
          Minimum Bidding amount is&nbsp;&nbsp;
          <Typography variant="h6" color="textPrimary" display="inline-flex">
            <CurrencyPrice
              price={product?.price}
              currency={product?.currency || "USD"}
            />
          </Typography>
        </Typography>
        <Typography variant="subtitle2">
          Please enter higher than top bid amount to get more probability of
          getting this product. Wish you a good luck.
        </Typography>
        <Select
          labelId="currency-label"
          id="currency"
          name="currency"
          value={currency || "USD"}
          onChange={setCurrency}
          label="Select Currency"
          fullWidth
        >
          {Object.entries(COUNTRY_CURRENCY).map(([currency, val], index) => (
            <MenuItem value={val.toUpperCase()} key={`${currency}-${index}`}>
              {val.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="outlined-basic"
          label="Enter Amount"
          variant="outlined"
          name="bidAmount"
          type="number"
          onChange={handleAmountChange}
          sx={{ mt: 3, width: "100%" }}
          error={!!error}
          helperText={error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currencySymbols[currency] || currency}
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" autoFocus>
          {myBid && myBid.uuid ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BidDialog;
