import React, { useEffect } from "react";
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CategoryProps, ICategory, ISubCategory } from "./category.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Category: React.FC<CategoryProps> = ({
  category,
  handleNext,
  handleChange,
}) => {
  const productForm = useSelector(
    (state: RootState) => state.products.productForm
  );
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNextClick = (category: ICategory, subcategory: ISubCategory) => {
    handleNext();
    handleChange(category, subcategory);
  };

  useEffect(() => {
    if (productForm.category.uuid === category.uuid && !open) {
      setOpen(true);
    }
  }, [productForm.category, category, open]);

  return (
    <Box>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={category.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category.subcategories.map((subcategory) => (
            <ListItemButton
              key={subcategory.uuid}
              sx={{ pl: 4 }}
              onClick={() => handleNextClick(category, subcategory)}
            >
              <ListItemText primary={subcategory.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default Category;
