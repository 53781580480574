import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import theme from "./theme";
import "./index.css";
import store from "./store/store";
import Toaster from "./components/toaster/Toaster";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLoginProvider } from "./components/auth/GoogleLoginContext";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  const queryClient = new QueryClient();

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
              >
                <GoogleLoginProvider>
                  <App />
                </GoogleLoginProvider>
              </GoogleOAuthProvider>
              <Toaster />
            </QueryClientProvider>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}
