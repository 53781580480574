import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface CurrencyState {
  rates: Record<string, number>;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CurrencyState = {
  rates: {},
  status: "idle",
  error: null,
};

const LOCAL_STORAGE_KEY = "currencyRates";
const CACHE_VALIDITY_PERIOD = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Utility function to check if cached data is still valid
const isCacheValid = (timestamp: number) => {
  return Date.now() - timestamp < CACHE_VALIDITY_PERIOD;
};

// Async thunk to fetch currency rates based on location
const fetchCurrencyRates = createAsyncThunk(
  "currency/fetchRates",
  async (_, { rejectWithValue }) => {
    try {
      const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (cachedData) {
        const { rates, timestamp } = JSON.parse(cachedData);

        if (isCacheValid(timestamp)) {
          return rates;
        }
      }
      const response = await fetch(
        `https://latest.currency-api.pages.dev/v1/currencies/usd.json`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch currency rates");
      }

      const data = await response.json();
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ rates: data.usd, timestamp: Date.now() })
      );
      return data.usd;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencyRates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrencyRates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rates = action.payload;
      })
      .addCase(fetchCurrencyRates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default currencySlice.reducer;
export { fetchCurrencyRates };
