import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { ProductType } from "./product.type";

// Define interfaces for the data types
interface PriceInsight {
  date: string;
  inr: string;
  area: string;
}

type PriceInsightsTableProps = {
  priceInsightProducts: ProductType[];
};

const PriceInsightsTable: React.FC<PriceInsightsTableProps> = ({
  priceInsightProducts,
}) => {
  console.log("priceInsightProducts", priceInsightProducts);
  // Dummy data arrays with specified types
  const soldData: PriceInsight[] = [
    { date: "2024-08-01", inr: "₹10,00,000", area: "1200" },
    { date: "2024-08-05", inr: "₹12,00,000", area: "1500" },
    { date: "2024-08-10", inr: "₹11,50,000", area: "1400" },
  ];

  const rentedData: PriceInsight[] = [
    { date: "2024-08-01", inr: "₹50,000/month", area: "1200" },
    { date: "2024-08-06", inr: "₹55,000/month", area: "1500" },
    { date: "2024-08-12", inr: "₹60,000/month", area: "1400" },
  ];

  const renderTableBasedOnProductCategory = () => {
    if (priceInsightProducts.length) {
      const {
        category: { name: productCategory },
      } = priceInsightProducts[0];
      switch (productCategory) {
        case "Electronics":
          return (
            <>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Sold
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Sold for</TableCell>
                      <TableCell>Brand</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceInsightProducts.map((row: ProductType, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.soldDate
                            ? new Date(row.soldDate).toDateString()
                            : new Date().toDateString()}
                        </TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.specifications?.brand}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>
            </>
          );
        case "Properties":
          return (
            <>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Sold
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Sold for</TableCell>
                      <TableCell>Area (sqft)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceInsightProducts.map((row: ProductType, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.soldDate
                            ? new Date(row.soldDate).toDateString()
                            : new Date().toDateString()}
                        </TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.specifications?.carpetArea}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Rent
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Rented for</TableCell>
                        <TableCell>Area (sqft)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {priceInsightProducts.map((row: ProductType, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.soldDate
                            ? new Date(row.soldDate).toDateString()
                            : new Date().toDateString()}</TableCell>
                          <TableCell>{row.price}</TableCell>
                          <TableCell>{row.specifications?.carpetArea}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          );
        default:
          break;
      }
    }
    return null;
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6" gutterBottom>
        Price Insights
      </Typography>
      {/* <Typography variant="subtitle1" gutterBottom>
        2 Beds Apartment in DEC Tower 2
      </Typography> */}
      <Grid container spacing={2}>
        {renderTableBasedOnProductCategory()}
      </Grid>
    </Box>
  );
};

export default PriceInsightsTable;
