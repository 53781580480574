// src/components/ContentSection.tsx
import React from "react";
import { Paper, Typography } from "@mui/material";
import SearchBox from "./SearchBar";

const ContentSection: React.FC = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "20px",
      }}
    >
      <Typography variant="h4" sx={{ color: "#fff" }}>
        Bidding made exciting and easy
      </Typography>
      <SearchBox />
    </Paper>
  );
};

export default ContentSection;
