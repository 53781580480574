import React from "react";
import { Container, List } from "@mui/material";
import Category from "./Category";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ICategory, ISubCategory } from "./category.type";

type CategoryPageProps = {
  handleNext: () => void;
  handleChange: (category: ICategory, subcategory: ISubCategory) => void;
};

const CategoryPage: React.FC<CategoryPageProps> = ({
  handleNext,
  handleChange,
}) => {
  const { category: categories } = useSelector(
    (state: RootState) => state.category
  );

  return (
    <Container>
      <List>
        {categories.map((categoryItem) => (
          <Category
            key={categoryItem.uuid}
            category={categoryItem}
            handleNext={handleNext}
            handleChange={handleChange}
          />
        ))}
      </List>
    </Container>
  );
};

export default CategoryPage;
