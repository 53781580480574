import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFilter,
  FilterOptions,
} from "../../components/products/SubFilters/FilterOptions";

interface FilterState {
  loading: boolean;
  filters: IFilter;
  filterOptions: FilterOptions;
  error: string | null;
}

const initialState: FilterState = {
  loading: false,
  filters: {
    search: "",
    price: "",
    category: {
      uuid: "",
    },
    subcategory: {
      uuid: "",
    },
    sortBy: "",
    tags: "",
  },
  filterOptions: {
    productType: [],
    productSubCategory: [],
  },
  error: null,
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<any>) {
      console.log(action.payload, "action.payload")
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearFilters(state) {
      state.filters = { ...initialState.filters };
    },
    createProductFilters(state, action: PayloadAction<any>) {
      state.filterOptions = {
        ...state.filterOptions,
        productType: action.payload,
      };
    },
    createSubProductFilters(state, action: PayloadAction<any>) {
      state.filterOptions = {
        ...state.filterOptions,
        productSubCategory: action.payload,
      };
    },
  },
  extraReducers: (builder) => {},
});

export const { setFilters, clearFilters } = filterSlice.actions;

export default filterSlice.reducer;
