import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./slices/product.slice";
import locationReducer from "./slices/location.slice";
import categoryReducer from "./slices/category.slice";
import toastReducer from "./slices/toastSlice";
import authReducer from "./slices/auth.slice";
import filterReducer from "./slices/filter.slice";
import currencyReducer from "./slices/currency.slice";

const store = configureStore({
  reducer: {
    products: productsReducer,
    location: locationReducer,
    category: categoryReducer,
    toast: toastReducer,
    auth: authReducer,
    filter: filterReducer,
    currency: currencyReducer,
    // Add other reducers here
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
