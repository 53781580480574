// src/theme.ts
import { createTheme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypeText {
    tertiary?: string;
  }

  interface Theme {
    maxHeight: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
  }

  interface ThemeOptions {
    maxHeight?: {
      xs?: string;
      sm?: string;
      md?: string;
      lg?: string;
      xl?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1A4848", // Purple shade as the main color
      light: "#296968",
    },
    secondary: {
      main: "#f9f9f9", // Secondary white color
    },
    info: {
      main: "#3498db", // Blue color for info messages
    },
    error: {
      main: "#e74c3c", // Red color for error messages
    },
    warning: {
      main: "#f39c12", // Orange color for warning messages
    },
    text: {
      primary: "#333333", // Dark grey text
      secondary: "#666666", // Medium grey text
      tertiary: "#ffffff", // White text for tertiary usage
    },
    background: {
      default: "#ffffff", // White background
      paper: "#f5f5f5", // Light grey paper background
      orange: "#E56A2D",
    },
  },
  spacing: (factor: number) => `${8 * factor}px`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  maxHeight: {
    xs: "50vh", // Adjusted height for xs screens (mobile)
    sm: "50vh", // Adjusted height for sm screens (tablet)
    md: "90vh", // Adjusted height for md screens (small desktop)
    lg: "100vh", // Adjusted height for lg screens (large desktop)
    xl: "100vh", // Adjusted height for xl screens (extra large desktop)
  },
} as ThemeOptions);

export default theme;
