// src/App.tsx
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import LoginDialog from "./components/auth/Login";
import Footer from "./components/Footer";
import Dashboard from "./components/dashboard/Dashboard";
import ProductsDashboard from "./components/products/Products";
import ProductDetails from "./components/products/ProductDetailsNew";
import SellerProfile from "./components/products/SellerProfile";
import AddProduct from "./components/products/AddProducts/AddProducts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import {
  fetchCategory,
  getCategoriesSelector,
} from "./store/slices/category.slice";
import Profile from "./components/profile/Profile";
import ProtectedRoute from "./routes/protected-routes";
import { useQuery } from "react-query";
import GlobalLoader from "./components/common/GlobalLoader";
import { clearFilters } from "./store/slices/filter.slice";
import { checkTokenValidity } from "./components/auth/Helper";
import { logout, setCurrentUserData } from "./store/slices/auth.slice";
import { logOutWithClearStorage } from "./components/utils/helpers";

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      console.log("Token stored", storedToken);
      const token = checkTokenValidity(storedToken);
      if (!token.success) {
        logOutWithClearStorage();
        dispatch(logout());
      } else {
        dispatch(setCurrentUserData(token.decodedToken));
      }
    }
  }, []);

  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state.category)
  );

  useQuery("fetchCategory", () => dispatch(fetchCategory()), {
    enabled: !categories.length,
  });

  useEffect(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <GlobalLoader />
      <Box sx={{ flex: 1 }}>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/products" element={<ProductsDashboard />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/productdetails" element={<ProductDetails />} />
          <Route path="/sellerprofile" element={<SellerProfile />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/myaccount/*" element={<Profile />} />
          </Route>
        </Routes>
      </Box>
      <LoginDialog />
      <Footer />
    </Box>
  );
};

export default App;
