import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductType,
  BaseProducts,
  PRODUCT_FORM_STATE,
} from "../../components/products/product.type";

import fetchWrapper from "../../components/utils/fetchWrapper";
import {
  convertToFilterFormat,
  flattenObject,
} from "../../components/utils/helpers";
import { showToast } from "./toastSlice";

interface ProductsState {
  loading: boolean;
  products: ProductType[];
  exclusiveProducts: ProductType[];
  productForm: any;
  error: string | null;
  apiResponse: Record<any, any>;
}

const initialState: ProductsState = {
  loading: false,
  products: [],
  exclusiveProducts: [],
  productForm: PRODUCT_FORM_STATE,
  error: null,
  apiResponse: {},
};

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (_, { getState }) => {
    const {
      filter: { filters },
    }: any = getState();
    const { search, filter } = convertToFilterFormat(filters);
    let filterURLQueryParams: string = "products?";
    if (search) {
      filterURLQueryParams += `search=${search}`;
    }
    if (filter && search) {
      filterURLQueryParams += `&filter=${JSON.stringify(filter)}`;
    } else if (filter) {
      filterURLQueryParams += `filter=${JSON.stringify(filter)}`;
    }
    const {
      data: { products },
    } = await fetchWrapper(filterURLQueryParams);
    return products as ProductType[];
  }
);

export const fetchExclusiveProducts = createAsyncThunk(
  "products/fetchExclusiveProducts",
  async () => {
    let filterURLQueryParams: string = `products?filter=${JSON.stringify({
      tags: "Exclusive",
    })}&limit=${5}`;
    const {
      data: { products },
    } = await fetchWrapper(filterURLQueryParams);
    return products as ProductType[];
  }
);

export const addProducts = createAsyncThunk(
  "products/addProducts",
  async (productData: any, { dispatch }) => {
    const formData = new FormData();
    const flattenedFormValues = flattenObject(productData);
    for (let key in flattenedFormValues) {
      if (key !== "isFormValid" && key !== "images") {
        if (flattenedFormValues.hasOwnProperty(key)) {
          formData.append(key, flattenedFormValues[key]);
        }
      }
    }
    if (Array.isArray(productData.images)) {
      productData.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });
    } else {
      formData.append("images", productData.images);
    }

    const response = await fetchWrapper("products", {
      method: "POST",
      body: formData,
      isFormData: true,
    });

    if (response.success) {
      dispatch(setApiResponse({ success: response.success }));
      dispatch(
        showToast({
          message: "Created product successfully.",
          severity: "success",
        })
      );
      return response.data as ProductType[];
    } else {
      dispatch(setApiResponse(response));
      dispatch(
        showToast({
          message: "Failed created product.",
          severity: "error",
        })
      );
      return [] as ProductType[];
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (productData: any, { dispatch }) => {
    const formData = new FormData();
    const flattenedFormValues = flattenObject(productData);
    for (let key in flattenedFormValues) {
      if (key !== "isFormValid" && key !== "images") {
        if (flattenedFormValues.hasOwnProperty(key)) {
          formData.append(key, flattenedFormValues[key]);
        }
      }
    }
    if (Array.isArray(productData.images)) {
      productData.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });
    } else {
      formData.append("images", productData.images);
    }

    const response = await fetchWrapper(`products/${productData.uuid}`, {
      method: "PUT",
      body: formData,
      isFormData: true,
    });

    if (response.success) {
      dispatch(setApiResponse({ success: response.success }));
      dispatch(
        showToast({
          message: "Product updated successfully.",
          severity: "success",
        })
      );
      return response.data as ProductType[];
    } else {
      dispatch(setApiResponse(response));
      dispatch(
        showToast({
          message: "Failed to update product details.",
          severity: "error",
        })
      );
      return [] as ProductType[];
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductForm(state, action: PayloadAction<BaseProducts>) {
      state.productForm = action.payload;
    },
    resetProductForm(state) {
      state.productForm = PRODUCT_FORM_STATE;
    },
    setApiResponse(state, action: PayloadAction<any>) {
      state.apiResponse = action.payload;
    },
    clearApiResponse(state) {
      state.apiResponse = {};
    },
  },
  extraReducers: (builder) => {
    // fetchProducts
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.products = action.payload;
          state.apiResponse = { success: false };
        }
      )
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.apiResponse = { success: false };
      });

    // addProducts
    builder
      .addCase(addProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.products.push(action.payload as any);
          state.productForm = PRODUCT_FORM_STATE;
        }
      )
      .addCase(addProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });

    // fetchExclusiveProducts
    builder
      .addCase(fetchExclusiveProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchExclusiveProducts.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
          state.exclusiveProducts = action.payload;
        }
      )
      .addCase(fetchExclusiveProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });

    // updateProduct
    builder
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateProduct.fulfilled,
        (state, action: PayloadAction<ProductType[]>) => {
          state.loading = false;
        }
      )
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { setProductForm, resetProductForm, setApiResponse } =
  productsSlice.actions;

export const getProduct = (state: ProductsState) => {
  return state.products;
};
export default productsSlice.reducer;
