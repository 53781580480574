import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  Typography,
  Stepper,
  Step,
  Box,
  StepButton,
} from "@mui/material";
import CategoryPage from "../../category/CategoryPage";
import ProductDetails from "./ProductDetails";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addProducts,
  updateProduct,
} from "../../../store/slices/product.slice";
import { ICategory } from "../../../components/category/category.type";
import { ISubCategory } from "../../../components/category/category.type";
import { BaseProducts, PRODUCT_FORM_STATE } from "../product.type";
import { useNavigate, useLocation } from "react-router-dom";
import { getCategoriesSelector } from "../../../store/slices/category.slice";
import { fetchSingleProductData } from "../../../components/services/product.service";
import { useQuery } from "react-query";
import { findNonEmptyValues } from "../../../components/utils/helpers";

const AddProduct: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const reactLocation = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(reactLocation.search),
    [reactLocation.search]
  );
  const isEdit =
    queryParams.get("action") &&
    queryParams.get("action") === "edit" &&
    queryParams.get("uuid");

  const [productForm, setProductForm] =
    useState<BaseProducts>(PRODUCT_FORM_STATE);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [productFormFields, setProductFormFields] = useState([]);

  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state.category)
  );

  const productApiResponse = useSelector(
    (state: RootState) => state.products.apiResponse
  );

  const location = useSelector((state: RootState) => state.location.location);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (productApiResponse.success && isEdit) {
      setTimeout(() => {
        navigate("/myaccount/listedItems");
      }, 1000);
    } else if (productApiResponse.success) {
      setTimeout(() => {
        navigate("/products");
      }, 1000);
    }
  }, [productApiResponse, navigate, isEdit]);

  const { data: currentProduct } = useQuery(
    ["products", [queryParams.get("uuid")]],
    () => fetchSingleProductData(`${queryParams.get("uuid")}`, false),
    {
      enabled: !!(
        queryParams.get("action") &&
        queryParams.get("action") === "edit" &&
        queryParams.get("uuid")
      ),
    }
  );

  const getSelectedCategoryFormFields = useCallback(
    (categoryUuid: string, subcategoryUuid: string) => {
      const getSubCategory = categories.find(
        (categoryItem: ICategory) => categoryItem.uuid === categoryUuid
      );
      const getFields = getSubCategory?.subcategories.find(
        (subcategoryItem: ISubCategory) =>
          subcategoryItem.uuid === subcategoryUuid
      )?.fields;
      return getFields;
    },
    []
  );

  const mapFetchedProductDetails = useCallback(() => {
    if (!currentProduct?.product) return;
    const { product } = currentProduct;
    const { category, subcategory, images } = product;
    if (category?.uuid && subcategory?.uuid) {
      const formFields = getSelectedCategoryFormFields(
        category.uuid,
        subcategory.uuid
      );
      console.log(
        "mapFetchedProductDetailss",
        formFields,
        productForm,
        currentProduct
      );
      const specifications: any = findNonEmptyValues(
        product.specifications,
        productForm.specifications
      );
      setProductForm((prevForm) => ({
        ...prevForm,
        title: product.title,
        description: product.description,
        price: product.price,
        location: product.location,
        specifications: specifications,
        category: {
          name: category.name,
          uuid: category.uuid,
        },
        subcategory: {
          name: subcategory.name,
          uuid: subcategory.uuid,
        },
      }));
      setImagePreviews(images);
      setProductFormFields((prevFields: any) => {
        if (JSON.stringify(prevFields) !== JSON.stringify(formFields)) {
          return formFields;
        }
        return prevFields;
      });
      setActiveStep(activeStep + 1);
    }
  }, [currentProduct, getSelectedCategoryFormFields]);

  useEffect(() => {
    mapFetchedProductDetails();
  }, [mapFetchedProductDetails]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCategoryChange = (
    category: ICategory,
    subcategory: ISubCategory
  ) => {
    const { name, uuid } = category;
    const { name: subcategoryName, uuid: subcategoryUuid } = subcategory;
    setProductForm({
      ...productForm,
      location: {
        city: productForm.location.city || location.city,
        state: productForm.location.state || location.state,
        country: productForm.location.country || location.country,
      },
      category: {
        name,
        uuid,
      },
      subcategory: {
        uuid: subcategoryUuid,
        name: subcategoryName,
      },
    });
    if (uuid && subcategoryUuid) {
      const formFields: any = getSelectedCategoryFormFields(
        uuid,
        subcategoryUuid
      );
      setProductFormFields(formFields);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("validateForm", productForm);
    if (validateForm()) {
      if (isEdit) {
        dispatch(
          updateProduct({ ...productForm, uuid: queryParams.get("uuid") })
        );
      } else {
        dispatch(addProducts(productForm));
      }
    } else {
      alert("Please fill out all required fields.");
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <CategoryPage
              handleNext={handleNext}
              handleChange={handleCategoryChange}
            />
          </>
        );
      case 1:
        return (
          <>
            <ProductDetails
              handleSubmit={handleSubmit}
              handleBack={handleBack}
              productForm={productForm}
              setProductForm={setProductForm}
              productFormFields={productFormFields}
              imagePreviews={imagePreviews}
              setImagePreviews={setImagePreviews}
            />
          </>
        );
    }
  };

  const validateForm = () => {
    return (
      productForm.title &&
      productForm.description &&
      productForm.currency &&
      productForm.price &&
      productForm.location &&
      productForm.category
    );
  };

  console.log("activeStep", activeStep, productForm);
  const steps = [
    activeStep === 1 &&
    productForm?.category?.name &&
    productForm?.subcategory?.name
      ? productForm?.category?.name + " / " + productForm?.subcategory?.name
      : "Category & Subcategory",
    "Product Details",
  ];

  console.log("steps", steps);
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Add a New Product
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepButton color="inherit" onClick={handleReset}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        {renderStepContent(activeStep)}
        {/* <Grid container spacing={2} marginTop={3} justifyContent="flex-end">
          {activeStep !== 0 && (
            <Grid item>
              <Button onClick={handleBack}>Back</Button>
            </Grid>
          )}
          {activeStep === steps.length - 1 && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!productForm.category}
              >
                Finish
              </Button>
            </Grid>
          )}
        </Grid> */}
      </Box>
    </Container>
  );
};

export default AddProduct;
