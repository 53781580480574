import {
  ListSubheader,
  Grid,
  SelectChangeEvent,
  TextField,
  Button,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  getCategoriesSelector,
  getSubCategoriesSelector,
} from "../../../store/slices/category.slice";
import { setFilters, clearFilters } from "../../../store/slices/filter.slice";
import { fetchProducts } from "../../../store/slices/product.slice";
import { useDispatch } from "react-redux";
import DropdownWithPriceRange from "../../../components/common/DropdownWithPriceRange";
import { updateNestedValue } from "../../../components/utils/helpers";
import { DEAL_CATEGORY } from "./FilterOptions";

type SubFiltersProps = {};

const SubProductFilters: React.FC<SubFiltersProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );
  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state.category)
  );
  const subCategories = useSelector((state: RootState) =>
    getSubCategoriesSelector(state.category, selectedFilters.category.uuid)
  );
  const [price, setPrice] = useState<{ min: number; max: number }>({
    min: 0,
    max: 0,
  });


  const handleOnChange = (event: SelectChangeEvent<string>) => {
    const { value, name } = event.target;
    let updatedValues;
    if (name === "category.uuid") {
      const updateCategory = updateNestedValue(
        { ...selectedFilters },
        name,
        value
      );
      updatedValues = updateNestedValue(
        { ...updateCategory },
        "subcategory.uuid",
        ""
      );
    } else {
      updatedValues = updateNestedValue({ ...selectedFilters }, name, value);
    }
    dispatch(setFilters(updatedValues));
  };

  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    let updatedValues;
    if (name === "category.uuid") {
      updatedValues = updateNestedValue(
        { ...selectedFilters, "subcategory.uuid": "" },
        name,
        value
      );
    } else {
      updatedValues = updateNestedValue({ ...selectedFilters }, name, value);
    }
    dispatch(setFilters(updatedValues));
  };

  const onSearchClick = () => {
    dispatch(fetchProducts());
  };

  const onClearSearchClick = () => {
    dispatch(clearFilters());
    dispatch(fetchProducts());
    setPrice({ min: 0, max: 0 })
  };

  return (
    <ListSubheader sx={{ mt: 5, bgcolor: "white" }}>
      <Grid container columns={16}>
        <Grid item>
          <TextField
            id="outlined-basic"
            label="Search for properties..."
            variant="outlined"
            size="small"
            name="search"
            value={selectedFilters.search}
            onChange={handleInputOnChange}
            sx={{ m: 1 }}
          />
        </Grid>
        <Grid item>
          <SelectDropdown
            label="Category"
            name="category.uuid"
            value={selectedFilters.category.uuid}
            options={categories}
            onChange={handleOnChange}
            sxOptions={{ m: 1, width: 180 }}
          />
        </Grid>
        <Grid>
          <SelectDropdown
            label="Sub category"
            name="subcategory.uuid"
            value={selectedFilters.subcategory.uuid}
            options={subCategories}
            onChange={handleOnChange}
            sxOptions={{ m: 1, width: 180 }}
          />
        </Grid>
        <Grid>
          <DropdownWithPriceRange price={price} setPrice={setPrice} />
        </Grid>
        <Grid>
          <SelectDropdown
            label="Tags"
            name="tags"
            value={selectedFilters.tags}
            options={DEAL_CATEGORY}
            onChange={handleOnChange}
            sxOptions={{ m: 1, width: 150 }}
          />
        </Grid>
        <Grid>
          <Button variant="contained" onClick={onSearchClick} sx={{ m: 1 }}>
            Search
          </Button>
          <Button variant="outlined" onClick={onClearSearchClick} sx={{ m: 1 }}>
            Clear
          </Button>
        </Grid>
      </Grid>

      {/* <Grid
        container
        spacing={{ xs: 2.5, md: 2.5 }}
        columns={{ xs: 3, sm: 4, md: 12 }}
        columnGap={1}
      >
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          size="small"
          name="search"
          value={selectedFilters.search}
          onChange={handleInputOnChange}
          sx={{ m: 1 }}
        />

        <SelectDropdown
          label="Category"
          name="category.uuid"
          value={selectedFilters.category.uuid}
          options={categories}
          onChange={handleOnChange}
          sxOptions={{ m: 1, minWidth: 180 }}
        />

        <SelectDropdown
          label="Sub category"
          name="subcategory.uuid"
          value={selectedFilters.subcategory.uuid}
          options={subCategories}
          onChange={handleOnChange}
          sxOptions={{ m: 1, minWidth: 180 }}
        />

        <DropdownWithPriceRange />
      </Grid>
      <Button variant="contained" onClick={onSearchClick}>
        Search
      </Button> */}
    </ListSubheader>
  );
};

export default SubProductFilters;
