import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store/store";
//import { useGoogleLogin } from "../components/auth/GoogleLoginContext";

interface ProtectedRouteProps {
  children?: React.ReactNode;
  [key: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  ...rest
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  //const { googleLoginState } = useGoogleLogin();

  const isAuthenticated = user.isUserLoggedIn;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
