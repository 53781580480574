import React from "react";
import { ProductType } from "../../../components/products/product.type";
import { UserProfile } from "../types";
import { Box, Container, Grid, Typography } from "@mui/material";
import Product from "../../../components/products/Product";
import { changeStatusProductById } from "../../services/product.service";
import ConfirmDialog from "../../products/ConfirmDialog";

type UserAddedProductItemsProps = {
  items: ProductType[];
  category: string;
  label: string;
  currentUserDetails: { user: UserProfile; products: ProductType[] };
  handleDeleteProduct: (productId: string, status: string) => void;
};

const UserAddedProductItems: React.FC<UserAddedProductItemsProps> = ({
  items,
  category,
  label,
  currentUserDetails,
  handleDeleteProduct,
}) => {
  const isAdmin = currentUserDetails.user.role === "ADMIN";
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [currentProduct, setCurrentProduct] = React.useState("");

  const handleConfirm = () => {
    if (currentProduct) {
      handleDeleteProduct(currentProduct, "ARCHIVED");
    }
    setDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        {items.length > 0 ? (
          <Container>
            <Typography variant="h4" gutterBottom>
              {label} List
            </Typography>
            <Grid container spacing={3}>
              {items.map((item: ProductType) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={item?.uuid}>
                    <Product
                      product={item}
                      onAddToCart={() =>
                        console.log(`Added ${item.title} to cart`)
                      }
                      showDelete={category === "Listed"}
                      showEdit={
                        category === "Listed" &&
                        item.seller.email === currentUserDetails.user.email
                      }
                      showApproveButton={
                        category === "approveProducts" && isAdmin
                      }
                      approveProduct={(e) => {
                        e.stopPropagation();
                        changeStatusProductById(item.uuid, "APPROVED");
                      }}
                      rejectProduct={(e) => {
                        e.stopPropagation();
                        changeStatusProductById(item.uuid, "DRAFT");
                      }}
                      deleteProduct={(e) => {
                        e.stopPropagation();
                        setDialogOpen(true);
                        setCurrentProduct(item.uuid);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        ) : (
          <Typography variant="h5">No items found in this category.</Typography>
        )}
      </Box>
      <ConfirmDialog
        open={dialogOpen}
        title="Confirm Product Delete"
        description={`Are you sure you want to delete this product?`}
        onCancel={() => {
          setDialogOpen(false);
          setCurrentProduct("");
        }}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default UserAddedProductItems;
