// src/DataTable.tsx
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableSortLabel,
} from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface Column {
  Header: string;
  accessor: string | ((row: any) => any);
}

interface DataRow {
  [key: string]: any;
}

interface DataTableProps {
  columns: Column[];
  data: DataRow[];
}

// Function to flatten nested objects
const flattenObject = (
  obj: any,
  parentKey: string = "",
  res: any = {}
): any => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], newKey, res);
      } else {
        res[newKey] = obj[key];
      }
    }
  }
  return res;
};

const getValue = (row: DataRow, accessor: string | ((row: DataRow) => any)) => {
  if (typeof accessor === "function") {
    return accessor(row);
  }

  // Handle nested fields
  return accessor.split(".").reduce((value, key) => value[key], row);
};

const DataTable: React.FC<DataTableProps> = ({ columns, data }) => {
  const handleDownloadExcel = () => {
    // Flatten data
    const flattenedData = data.map((row) => flattenObject(row));
    console.log("Flattened Data:", flattenedData); // Debugging line

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    // Write the workbook to binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert binary string to array buffer
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff;

    // Save file
    saveAs(new Blob([buf], { type: "application/octet-stream" }), "data.xlsx");
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleDownloadExcel}
        style={{ margin: "16px" }}
      >
        Download as Excel
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.Header}>
                  <TableSortLabel>{column.Header}</TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column) => (
                  <TableCell key={column.Header}>
                    {getValue(row, column.accessor)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataTable;
