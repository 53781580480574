import React from "react";
import { CircularProgress, Box } from "@mui/material";
import { useIsFetching } from "react-query";

const GlobalLoader: React.FC = () => {
  const isFetching = useIsFetching();

  return isFetching ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  ) : null;
};

export default GlobalLoader;
