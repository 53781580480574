export type IFilter = {
  search: string;
  price: string;
  category: {
    uuid: string;
  },
  subcategory: {
    uuid: string;
  };
  sortBy: string;
  tags: string;
  limit?: number;
};

export type FilterOptions = {
  [key: string]: string[];
};

export type ProductType = {
  id: number;
  name: string;
  filters: FilterOptions;
};

export const FILTER_OPTIONS = {
  productType: {
    name: "",
  },
};

export const DEAL_CATEGORY = [
  {
    id: "exclusive",
    label: "Exclusive",
    value: "Exclusive"
  },
  {
    id: "popular",
    label: "Popular",
    value: "Popular"
  }
]