import React from "react";

interface TruncatedTextProps {
  text: string;
  maxLength?: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  maxLength = 100,
}) => {
  const truncatedText =
    text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;

  return <>{truncatedText}</>;
};

export default TruncatedText;
