import React, { useState } from "react";
import Box from "@mui/material/Box";
import { GoogleLogin } from "@react-oauth/google";
//import { useGoogleLogin } from "./GoogleLoginContext"; // Import the hook
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./Helper";
import { useDispatch } from "react-redux";
import { verifyLogin } from "../../store/slices/auth.slice";
import { AppDispatch } from "../../store/store";
import Alert from "@mui/material/Alert";

//const userService = new UserService(process.env.REACT_APP_BE_BASE_URL || "");

export const AccountMenu = ({ handleClose }: { handleClose: () => void }) => {
  const [error, setError] = useState(false);
  //const { setGoogleLoginState } = useGoogleLogin(); // Use the hook to access Google login state
  const dispatch = useDispatch<AppDispatch>();
  const handleSignInSuccess = async (tokenResponse: any) => {
    try {
      const googleUser = jwtDecode<JwtPayload>(tokenResponse.credential);
      //const loginState = setUserData(googleUser);
      if (googleUser?.email) {
        const result = await dispatch(
          verifyLogin({
            email: googleUser?.email || "",
            password: undefined,
            idToken: tokenResponse.credential,
          })
        );

        if (result && result?.payload?.success) {
          // localStorage.setItem("googleToken", tokenResponse.credential);
          // //localStorage.setItem("email", tokenResponse.email);
          // const googleUser = jwtDecode<JwtPayload>(result.payload.data.token);
          // setGoogleLoginState({
          //   isAuthenticated: true,
          //   userData: {
          //     name: `${googleUser.first_name} ${googleUser.last_name}`,
          //     emailId: googleUser.email,
          //     firstName: googleUser.first_name || "",
          //     lastName: googleUser.last_name || "",
          //   },
          // });
          handleClose();
        } else {
          setError(true);
        }
      }
    } catch (e) {
      console.log("Error in decoding token", e);
      setError(true);
    }
  };

  const handleSignInFailure = (error: any) => {
    console.log("Error signing in:", error);
    setError(true);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {error && (
          <Alert severity="error" sx={{ width: "100%", marginBottom: 2 }}>
            There was an error, please try again
          </Alert>
        )}
        <GoogleLogin
          onSuccess={handleSignInSuccess}
          // @ts-ignore
          onError={handleSignInFailure}
          auto_select
          size="large"
          width={"300px"}
          ux_mode="popup"
        />
      </Box>
    </React.Fragment>
  );
};
