import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";

type PriceSortProps = {
  name: string;
  sortBy: string;
  handleSortChange: (event: SelectChangeEvent<string>) => void
};

const PriceSort: React.FC<PriceSortProps> = ({ name, sortBy, handleSortChange }) => {

  return (
    <>
      <Grid container justifyContent="flex-end" alignItems="center">
        <FormControl variant="outlined" size="small" style={{ minWidth: 150 }}>
          <InputLabel>Sort By</InputLabel>
          <Select name={name} value={sortBy} onChange={handleSortChange} label="Sort By">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="price_asc">Price: Low to High</MenuItem>
            <MenuItem value="price_desc">Price: High to Low</MenuItem>
            <MenuItem value="date_newest">Newest First</MenuItem>
            <MenuItem value="date_oldest">Oldest First</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default PriceSort;
