import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Delete, PhotoCamera } from "@mui/icons-material";
import { COUNTRY_CURRENCY, FILE_CONFIG } from "../../utils/constants";
import { PRODUCT_FORM_STATE } from "../product.type";
import BasicDatePicker from "../../common/DatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import GeosuggestDropdown from "../../GeosuggestDropdown";

type ProductDetailsProps = {
  handleSubmit: (formValues: any) => void;
  handleBack: () => void;
  productForm: any;
  setProductForm: (formValues: any) => void;
  productFormFields: any;
  imagePreviews: string[];
  setImagePreviews: (filePreview: any) => void;
};

const ProductDetails: React.FC<ProductDetailsProps> = ({
  handleSubmit,
  handleBack,
  productForm,
  setProductForm,
  productFormFields,
  imagePreviews,
  setImagePreviews,
}) => {
  //const [geoEnabled, setGeoEnabled] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<string>("");

  const location = useSelector((state: RootState) => state.location.location);

  useEffect(() => {
    if (productForm.location) {
      setProductForm({
        ...productForm,
        currency:
          COUNTRY_CURRENCY[
            (productForm.location?.country as keyof typeof COUNTRY_CURRENCY) ||
              (location?.country as keyof typeof COUNTRY_CURRENCY)
          ],
      });
    }
  }, [productForm.location]);

  const handleFormReduxState = (
    name: string,
    value: string | boolean | number | Date
  ) => {
    if (name.includes("specifications")) {
      name = name.split(".")[1];
      setProductForm({
        ...productForm,
        specifications: {
          ...productForm.specifications,
          [name]: value,
        },
      });
    } else {
      setProductForm({
        ...productForm,
        [name]: value,
      });
    }
  };

  const handleDateChange = (date: Date) => {
    if (date) {
      handleFormReduxState("bidEndDate", new Date(date).toISOString());
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type, checked, files } =
      event.target as HTMLInputElement;

    if (files) {
      const errorMessages: string[] = [];
      const uploadedFiles = Array.from(files);
      const previewUrls: any = uploadedFiles.map((file) => {
        if (file.size > FILE_CONFIG.maxFileSize) {
          errorMessages.push(`File ${file.name} exceeds the 10 MB limit.`);
          return [];
        } else {
          return URL.createObjectURL(file);
        }
      });
      if (errorMessages.length > 0) {
        setFileSizeError(errorMessages.join(" "));
      } else {
        setFileSizeError("");
        setProductForm({
          ...productForm,
          images: [...productForm.images, ...uploadedFiles],
        });
        setImagePreviews([...imagePreviews, ...previewUrls]);
      }
    } else if (type === "checkbox") {
      handleFormReduxState(name, checked.toString());
    } else {
      handleFormReduxState(name, value);
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    let { name, value } = event.target;
    handleFormReduxState(name, value);
  };

  const handleRemoveImage = (index: number) => {
    setProductForm((prevImages: any) => ({
      ...prevImages,
      images: prevImages.images.filter((_: any, i: number) => i !== index),
    }));
    setImagePreviews((prevPreviews: any) =>
      prevPreviews.filter((_: any, i: number) => i !== index)
    );
  };

  const handleReset = () => {
    setFileSizeError("");
    setProductForm(PRODUCT_FORM_STATE);
  };

  const renderField = (field: any) => {
    const fieldValue = productForm.specifications[field.name] || "";
    switch (field.type) {
      case "text":
        return (
          <TextField
            label={field.displayName}
            variant="outlined"
            name={`specifications.${field.name}`}
            value={fieldValue}
            onChange={handleFieldChange}
            fullWidth
            required={field.required}
          />
        );
      case "number":
        return (
          <TextField
            label={field.displayName}
            variant="outlined"
            name={`specifications.${field.name}`}
            value={fieldValue}
            onChange={handleFieldChange}
            fullWidth
            required={field.required}
            type="number"
          />
        );
      case "dropdown":
        return (
          <FormControl fullWidth variant="outlined">
            <InputLabel>{field.displayName}</InputLabel>
            <Select
              name={`specifications.${field.name}`}
              value={fieldValue}
              onChange={handleSelectChange}
              label={field.displayName}
            >
              {field.options.map((option: any) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControlLabel
            control={
              <Checkbox
                name={`specifications.${field.name}`}
                checked={fieldValue === true}
                onChange={handleFieldChange}
              />
            }
            label={field.displayName}
          />
        );
      case "button":
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => alert(`${field.displayName} clicked!`)}
          >
            {field.displayName}
          </Button>
        );
      default:
        return null;
    }
  };

  const setLocation = (location: {}) => {
    setProductForm({
      ...productForm,
      location,
    });
  };

  const filterLocation = (location: any) =>
    [location.city, location.state, location.country]
      .filter(Boolean) // Remove any falsy values (e.g., undefined, null, "")
      .join(", ");

  console.log("productForm", productForm);
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12}>
          <TextField
            label="Title"
            variant="outlined"
            name="title"
            value={productForm.title || ""}
            onChange={handleFieldChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            value={productForm.description || ""}
            onChange={handleFieldChange}
            fullWidth
            multiline
            rows={4}
            required
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Select
            labelId="currency-label"
            id="currency"
            name="currency"
            value={productForm.currency || "USD"}
            onChange={handleSelectChange}
            label="Select Currency"
            fullWidth
          >
            {Object.entries(COUNTRY_CURRENCY).map(([currency, val], index) => (
              <MenuItem value={val.toUpperCase()} key={`${currency}-${index}`}>
                {val.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={8} sm={8}>
          <TextField
            label="Price"
            name="price"
            variant="outlined"
            value={productForm.price || ""}
            onChange={handleFieldChange}
            fullWidth
            required
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <GeosuggestDropdown
            showInput={true}
            setShowInput={() => {}}
            spacing={true}
            hideDelete={true}
            value={filterLocation(productForm.location) || ""}
            onChange={setLocation}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {/* <TextField
            label="Tag"
            variant="outlined"
            name="tag"
            value={productForm.tag || ""}
            onChange={handleFieldChange}
            fullWidth
          /> */}
          <BasicDatePicker
            label="Bidding Ends On"
            onChange={handleDateChange}
            name="bidEndDate"
            value={dayjs(productForm?.bidEndDate) || dayjs().add(15, "day")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCamera />}
            fullWidth
          >
            Upload Images
            <input
              type="file"
              hidden
              multiple
              accept="image/*"
              onChange={handleFieldChange}
            />
          </Button>
          {fileSizeError && (
            <Typography variant="body2" color="error">
              {fileSizeError}
            </Typography>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            {imagePreviews.map((preview, index) => (
              <Box key={index} position="relative" mr={2} mb={2}>
                <img
                  src={preview}
                  alt={`preview-${index}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
                <IconButton
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: "red",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Dynamic Fields */}
        {/* <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Subcategory</InputLabel>
            <Select
              value={productForm?.subcategory?.uuid || ""}
              onChange={handleSubcategoryChange}
              label="Subcategory"
            >
              {categoryData.subcategories.map((sub: any) => (
                <MenuItem
                  key={sub.uuid}
                  value={sub.uuid}
                  selected={selectedSubcategory?.uuid === sub.uuid}
                >
                  {sub.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        {productFormFields &&
          productFormFields.map((field: any) => (
            <Grid item xs={12} sm={12} key={field.name}>
              {renderField(field)}
            </Grid>
          ))}
        <Box>
          <Grid container spacing={2} marginTop={3} justifyContent="flex-end">
            <Grid item>
              <Button onClick={handleBack}>Back</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Post Now
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleReset}>Reset</Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default ProductDetails;
