import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Grid,
  Button,
  CardActions,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ProductType } from "./product.type";
import moment from "moment";
import { useNavigate, createSearchParams } from "react-router-dom";
import TruncatedText from "../Truncated.wrapper";
import { orange } from "@mui/material/colors";
import noImage from "../../assets/Noimage.jpg";
import CurrencyPrice from "../common/PriceWithCurrency";
import { filterLocation } from "../services/product.service";

interface ProductProps {
  product: ProductType;
  onAddToCart?: () => void;
  approveProduct?: (e: any) => void;
  rejectProduct?: (e: any) => void;
  deleteProduct?: (e: any) => void;
  showButtons?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  showApproveButton?: boolean;
}

const Product: React.FC<ProductProps> = ({
  product,
  onAddToCart,
  approveProduct,
  rejectProduct,
  deleteProduct,
  showButtons = true,
  showDelete = false,
  showEdit = false,
  showApproveButton = false,
}) => {
  const [difference, setDifference] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const postedDate = moment(product?.postedAt);
    const now = moment();
    const daysDifference = now.diff(postedDate, "days");

    let diffString = "";
    if (daysDifference === 0) {
      diffString = "Today";
    } else if (daysDifference === 1) {
      diffString = "Yesterday";
    } else if (daysDifference < 30) {
      diffString = `${daysDifference} days ago`;
    } else {
      const monthsDifference = now.diff(postedDate, "months");
      diffString = `${monthsDifference} month${
        monthsDifference > 1 ? "s" : ""
      } ago`;
    }

    setDifference(diffString);
  }, [product?.postedAt]);

  const handleEdit = () => {
    navigate({
      pathname: "/add-product",
      search: `?${createSearchParams({
        action: "edit",
        uuid: product.uuid,
      })}`,
    });
    console.log(`Edit product with id: ${product.uuid}`);
  };

  const handleDelete = () => {
    console.log(`Delete product with id: ${product.uuid}`);
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        boxShadow: 3,
        minHeight:
          showEdit || showDelete || showApproveButton ? "62vh" : "50vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onClick={() => {
        navigate(`/products/${product.uuid}`);
      }}
    >
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="200"
          src={product?.images?.[0] ? product.images[0] : noImage}
          alt="Product image"
        />
        {product?.status && (
          <Chip
            label={product.status}
            color="primary"
            sx={{ position: "absolute", top: 10, left: 10 }}
          />
        )}
        {product?.highestBid?.amount && product?.bidEndDate && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 0,
              display: "flex",
              alignItems: "center",
              backgroundColor: orange[800],
              color: "white",
              borderRadius: "5px 0px 0 5px",
            }}
          >
            <Chip
              label="Bid: "
              color="secondary"
              sx={{
                backgroundColor: orange[800],
                color: "white",
                marginRight: "5px",
                fontSize: "16px",
              }}
            />
            <CurrencyPrice
              price={product?.highestBid?.amount}
              currency={product?.highestBid?.currency || "USD"}
            />
          </Box>
        )}
      </Box>
      <CardContent
        sx={{
          flex: "1 0 auto",
          height: "25vh",
          borderTop:
            product?.highestBid?.amount && product?.bidEndDate
              ? `5px solid ${orange[800]}`
              : "",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          title={product?.title}
        >
          {product?.title && (
            <TruncatedText text={product?.title} maxLength={20} />
          )}
        </Typography>
        <Typography variant="h5" color="textPrimary">
          <CurrencyPrice
            price={product?.price}
            currency={product?.currency || "USD"}
          />
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ mb: 2, height: "10vh" }}
          title={product?.description}
        >
          {product?.description && (
            <TruncatedText text={product?.description} />
          )}
        </Typography>
        <Box sx={{ mt: "auto" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                {filterLocation(product?.location)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textPrimary">
                {difference}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      {(showEdit || showDelete || showApproveButton) && (
        <CardActions sx={{ pb: 1, borderTop: "1px solid #eee" }}>
          {showApproveButton && (
            <>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={approveProduct}
                  sx={{ borderRadius: "0 0 0 4px" }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={rejectProduct}
                  sx={{ borderRadius: "0 0 0 4px" }}
                >
                  Reject
                </Button>
              </Grid>
            </>
          )}
          {showEdit && (
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
                sx={{ borderRadius: "0 0 0 4px" }}
              >
                Edit
              </Button>
            </Grid>
          )}
          {showDelete && (
            <Grid item xs={6}>
              <Button
                color="primary"
                fullWidth
                onClick={deleteProduct}
                sx={{ borderRadius: "0 0 4px 0" }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default Product;
