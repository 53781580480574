import React from "react";
import { Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProductType } from "../products/product.type";
import Product from "../products/Product";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { updateNestedValue } from "../../components/utils/helpers";
import { setFilters } from "../../store/slices/filter.slice";

const ExclusiveDeals: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );
  const exclusiveProducts: ProductType[] = useSelector((state: RootState) => state.products.exclusiveProducts);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  
  const handleExclusiveMoreProducts = () => {
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      "tags",
      "Exclusive"
    );
    dispatch(setFilters(updatedValues));
    navigate("/products");
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            m: "25px 0px 30px",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "center" : "space-between",
            alignItems: isSmallScreen ? "center" : "flex-start",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          Grab The Top Exclusive Deals
          <Button
            variant="outlined"
            color="primary"
            sx={{
              alignSelf: isSmallScreen ? "center" : "flex-start",
              mt: isSmallScreen ? 2 : 0,
              width: isSmallScreen ? "100%" : "auto",
            }}
            onClick={handleExclusiveMoreProducts}
          >
            Show More Products
          </Button>
        </Typography>
        <Grid container spacing={3} style={{ marginLeft: "0.5vw" }}>
          {exclusiveProducts.map((product, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={product?.title + "_" + index}
              onClick={() => navigate(`/products/${product.uuid}`)}
            >
              <Product product={product} showButtons={false} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExclusiveDeals;
