import React, { useState } from "react";
import "./ProductListcss.css";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import noImage from "../../assets/Noimage.jpg"; 

const Carousel = (images: any) => {
  const data = images?.images?.length
  ? images.images.map((image: string, index: number) => {
      return {
        src: image,
        alt: `Image ${index}`,
      };
    })
  : [
      {
        src: noImage,
        alt: "NO Image",
      },
    ];

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };

  const [carousel, setStyle] = useState("carousel");

  const changeStyle = () => {
    setStyle(carousel !== "carousel" ? "carousel" : "carouselhover");
  };

  // State for the indicator slide index
  const [indicatorSlide, setIndicatorSlide] = useState(0);

  const indicatorsPerPage = 6; // Updated to handle 6 indicators per page

  const maxIndicatorSlide = Math.floor((data.length - 1) / indicatorsPerPage);

  const nextIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === maxIndicatorSlide ? 0 : indicatorSlide + 1
    );
  };

  const prevIndicatorSlide = () => {
    setIndicatorSlide(
      indicatorSlide === 0 ? maxIndicatorSlide : indicatorSlide - 1
    );
  };

  return (
    <div className="slides">
      <div className={carousel}>
      {data.length<2? ( "" ) : ( <KeyboardArrowLeftIcon onClick={prevSlide} className="arrow arrow-left" /> )}
      
      {data.map((item: any, idx: number) => {
        
          return (
            <img
              src={item.src}
              alt={item.alt}
              key={idx}
              className={slide === idx ? "slide" : "slide slide-hidden"}
              onClick={changeStyle}
              style={{ maxHeight: "415px" }}
            />
          );
        })}
   
        {data.length<2? ( "" ) : (<KeyboardArrowRightIcon onClick={nextSlide} className="arrow arrow-right" />)}
      </div>

      {data.length > 1 && (
        <div className="indicators-container">
          

          <div className="indicators">
            <div  className="indicator-arrow-indicator-arrow-left" >
          {data.length > indicatorsPerPage && (
            <KeyboardArrowLeftIcon
              onClick={prevIndicatorSlide}
             
            />
          )}
          </div>
            {data
              .slice(indicatorSlide * indicatorsPerPage, indicatorSlide * indicatorsPerPage + indicatorsPerPage)
              .map((item: any, idx: number) => {
                return (
                  <img
                    src={item.src}
                    alt={item.alt}
                    key={indicatorSlide * indicatorsPerPage + idx}
                    className={
                      slide === indicatorSlide * indicatorsPerPage + idx
                        ? "indicator"
                        : "indicator indicator-inactive"
                    }
                    onClick={() => setSlide(indicatorSlide * indicatorsPerPage + idx)}
                  />
                );
              })}
               <div  className="indicator-arrow-indicator-arrow-right" >
              {data.length > indicatorsPerPage && (
            <KeyboardArrowRightIcon
              onClick={nextIndicatorSlide}
             
            />
          )}
          </div>
          </div>

          
        </div>
      )}
    </div>
  );
};

export default Carousel;
