import React, { useState } from "react";
import { Card, CardContent, Typography, Container } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../BreadCrumbs";
import Product from "../products/Product";
import useMediaQuery from "@mui/material/useMediaQuery";
import noProductsImage from "../../assets/no-products.webp";
import ProfileAvatar from "../../assets/profile-avatar.png";
import { useQuery } from "react-query";
import fetchWrapper from "../../components/utils/fetchWrapper";

export default function SellerProfile() {
  const location = useLocation();
  const dataSeller = location.state?.sellerData || null;
  const [showNoProducts] = useState(!dataSeller);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const apiurl = `products/user/${dataSeller}`;

  const { data, isLoading, error } = useQuery(
    ["sellerProducts", dataSeller],
    () => fetchWrapper(apiurl),
    {
      enabled: !!dataSeller,
    }
  );

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading seller's products.</Typography>;
  }

  const products = data?.data || [];
  const firstName = products[0]?.seller?.first_name || "N/A";
  const lastName = products[0]?.seller?.last_name || "N/A";

  return (
    <Container sx={{ marginRight: "4px", marginLeft: "4px" }}>
      <Box position="static" sx={{ padding: theme.spacing(2) }}>
        <Breadcrumb />
      </Box>

      <Grid sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Card
              sx={{
                maxWidth: "100%",
                background: "white",
                boxShadow: "0.8px 1px 3px 1px grey",
              }}
              
            >
              <CardContent>
                <Box sx={{ textAlign: "center" }}>
                  <img src={ProfileAvatar} alt="Profile" width={120} />
                </Box>
                <Typography gutterBottom variant="h5" component="div">
                  {firstName + " " + lastName}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={6} md={12}>
                    <Typography
                      sx={{ fontSize: "1rem" }}
                      variant="body2"
                      color="black"
                    >
                      Total Products: {products.length}
                    </Typography>
                   
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "75vw",
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              <CardContent>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    m: "25px 0px 20px",
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    justifyContent: isSmallScreen ? "center" : "space-between",
                    alignItems: isSmallScreen ? "center" : "flex-start",
                    textAlign: isSmallScreen ? "center" : "left",
                  }}
                >
                  Products
                </Typography>
                <Grid item xs={12} sm={12} md={12} container spacing={1}>
                  {products && products.length > 0
                    ? products.map((product: any) => (
                        <Grid item xs={12} sm={12} md={4} key={product.id}>
                          <Product product={product} />
                        </Grid>
                      ))
                    : showNoProducts && (
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <img
                              src={noProductsImage}
                              alt="No products available"
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              mt={2}
                            >
                              No products available at the moment.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
