import { useMutation, useQuery, useQueryClient } from "react-query";
import fetchWrapper, {
  RequestOptions,
} from "../../components/utils/fetchWrapper";

// export const bidService = {
//   placeBid,
//   getAllBids,
// };

const getAllBids = async (productId: string) => {
  return fetchWrapper(`bids/product/${productId}`);
};

async function placeBid(productId: string, amount: number, currency: string) {
  const options: RequestOptions = {
    method: "POST",
    body: { productId, amount, currency },
  };

  return await fetchWrapper("bids", options);
}

async function updateBid(bidId: string, amount: number, currency: string) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { amount, currency },
  };

  return await fetchWrapper(`bids/${bidId}`, options);
}

async function acceptBid(bidId: string, productId: string) {
  const options: RequestOptions = {
    method: "PATCH",
    body: { productId, isAccepted: true },
  };

  return await fetchWrapper(`bids/accept/${bidId}`, options);
}

export const useFetchBids = (productId: string) => {
  return useQuery<any, "bids">(
    ["bids", productId],
    () => getAllBids(productId),
    {
      staleTime: 50 * 60 * 1000, // Cache for 5 minutes
    }
  );
};

// Place bid mutation
export const usePlaceBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: { productId: string; amount: number; currency: string }) =>
      placeBid(bidData.productId, bidData.amount, bidData.currency),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
      },
      onError: (error) => {
        console.error("Failed to place bid:", error);
      },
    }
  );
};

// Update bid mutation
export const useUpdateBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: { bidId: string; amount: number; currency: string }) =>
      updateBid(bidData.bidId, bidData.amount, bidData.currency),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
      },
      onError: (error) => {
        console.error("Failed to update bid:", error);
      },
    }
  );
};

// Accept bid mutation
export const useAcceptBid = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (bidData: { bidId: string; productId: string }) =>
      acceptBid(bidData.bidId, bidData.productId),
    {
      onSuccess: () => {
        // Invalidate and refetch bids after placing a bid
        queryClient.invalidateQueries("bids");
      },
      onError: (error) => {
        console.error("Failed to accept bid:", error);
      },
    }
  );
};
