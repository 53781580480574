import { Typography } from "@mui/material";
import { COUNTRY_CURRENCY, currencySymbols } from "../utils/constants";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface CurrencyPriceProps {
  price: number;
  currency: string;
}

const CurrencyPrice: React.FC<CurrencyPriceProps> = ({ price, currency }) => {
  const { rates } = useSelector((state: RootState) => state.currency);
  const location = useSelector((state: RootState) => state.location.location);

  const targetCurrency =
    COUNTRY_CURRENCY[location?.country as keyof typeof COUNTRY_CURRENCY] ||
    "USD";
  const convertedPrice = useMemo(() => {
    const productRate = rates[currency.toLowerCase()] || 1;

    const targetRate = rates[targetCurrency.toLowerCase()] || 1;

    const priceInTargetCurrency = (price / productRate) * targetRate;
    return priceInTargetCurrency;
  }, [rates, currency, targetCurrency, price]);

  const formatPrice = (amount: number, currency: string) => {
    const symbol = currencySymbols[currency] || currency;
    return `${symbol} ${amount.toFixed(2)}`;
  };

  return (
    <Typography variant="inherit">
      {formatPrice(convertedPrice, targetCurrency)}
    </Typography>
  );
};

export default CurrencyPrice;
