import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { signUp } from "../../store/slices/auth.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

interface SignUpDialogProps {
  setSignUpDialog: (param: boolean) => void;
  handleVerifyEmail: (param: string) => void;
  closeLoginDialog: () => void;
}

const SignUpDialog: React.FC<SignUpDialogProps> = ({
  setSignUpDialog,
  closeLoginDialog,
  handleVerifyEmail,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const validateEmail = (email: string) => {
    let newErrors: string = "";
    if (!email) {
      newErrors = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors = "Email is not valid";
    }
    return newErrors;
  };
  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!otp) newErrors.firstName = "OTP is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    const emailError = validateEmail(email);
    if (emailError) newErrors.email = emailError;
    if (!password) newErrors.password = "Password is required";
    if (password.length < 8)
      newErrors.password = "Password must be at least 8 characters";
    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    if (validate()) {
      try {
        const resultAction = await dispatch(
          signUp({
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            otp,
          })
        );

        if (
          signUp.fulfilled.match(resultAction) &&
          resultAction.payload.success
        ) {
          console.log("Success:", resultAction.payload);
          handleClose();
        } else if (signUp.rejected.match(resultAction)) {
          setError(resultAction.error.message || "");
          console.error("Error:", resultAction.error.message);
        } else if (!resultAction.payload.success) {
          setError(resultAction.payload.message || "");
          console.error("Error:", resultAction.payload.message);
        }
      } catch (error) {
        setError("Unexpected server error");
        console.error("Unexpected error:", error);
      }
    }
  };

  const handleBack = () => {
    setSignUpDialog(false);
  };

  const handleClose = () => {
    setSignUpDialog(false);
    closeLoginDialog();
  };

  const handleSendOtp = () => {
    const newErrors = validateEmail(email);
    if (newErrors && newErrors.length > 0) {
      setErrors({ email: newErrors });
    } else {
      setIsOtpSent(true);
      handleVerifyEmail(email);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => setSignUpDialog(false)}
      maxWidth="xs"
      fullWidth
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleBack}
          sx={{ ml: 1 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <DialogTitle variant="h5" align="center" sx={{ flexGrow: 1, ml: -4 }}>
          Create a new account
        </DialogTitle>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{ mr: 1 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            p: 2,
          }}
        >
          {error && (
            <Alert severity="error">
              There was an error, please try again: {error}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  disabled={isOtpSent}
                  onChange={(e) => {
                    e.preventDefault();
                    setErrors({});
                    setEmail(e.target.value);
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              {!isOtpSent && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSendOtp}
                    sx={{ marginBottom: "16px" }}
                  >
                    Send OTP
                  </Button>
                </Grid>
              )}
              {isOtpSent && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="otp"
                      label="OTP"
                      type="text"
                      id="otp"
                      autoComplete="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      error={!!errors.otp}
                      helperText={errors.otp}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!errors.password}
                      helperText={errors.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmPassword"
                      autoComplete="new-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {isOtpSent && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignUpDialog;
