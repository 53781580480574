import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

interface LocationState {
  location: {
    city: string;
    state: string;
    country: string;
  };
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: LocationState = {
  location: {
    city: "",
    state: "",
    country: "",
  },
  status: "idle",
  error: null,
};

const LOCAL_STORAGE_KEY = "currentLocation";
const CACHE_VALIDITY_PERIOD = 24 * 60 * 60 * 1000; // 24 hours

export const fetchLocation = createAsyncThunk(
  "location/fetchLocation",
  async (_, { rejectWithValue }) => {
    try {
      const cachedData = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (cachedData) {
        const { location, timestamp } = JSON.parse(cachedData);

        if (Date.now() - timestamp < CACHE_VALIDITY_PERIOD) {
          return location;
        }
      }

      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );

      const { latitude, longitude } = position.coords;

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();

      let city = "";
      let state = "";
      let country = "";
      if (data.results && data.results[0]) {
        data.results[0].address_components.forEach(
          (component: { types: string[]; long_name: string }) => {
            if (component.types.includes("administrative_area_level_2")) {
              city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (component.types.includes("country")) {
              country = component.long_name;
            }
          }
        );

        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({
            location: {
              city,
              state,
              country,
            },
            timestamp: Date.now(),
          })
        );

        return {
          city,
          state,
          country,
        };
      } else {
        throw new Error("No results found");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation(
      state: any,
      action: PayloadAction<{ city: string; state: string; country: string }>
    ) {
      state.location = action.payload;
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          location: action.payload,
          timestamp: Date.now(),
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLocation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.location = action.payload as {
          city: string;
          state: string;
          country: string;
        };
      })
      .addCase(fetchLocation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { setLocation } = locationSlice.actions;

export default locationSlice.reducer;
