import React from "react";

interface ContactEmailTemplateProps {
  companyName: string;
  formData: {
    firstName: string;
    email: string;
    phone: string;
    note: string;
  };
}

const ContactEmailTemplate: React.FC<ContactEmailTemplateProps> = ({
  companyName = "BidzBay",
  formData,
}) => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
      <h2 style={{ color: "#333" }}>Thank you, {formData.firstName}!</h2>
      <p>
        We appreciate you reaching out to {companyName}. Our team will connect
        with you shortly.
      </p>
      <p>
        We will contact you via your phone number:{" "}
        <strong>{formData.phone}</strong> or email:{" "}
        <strong>{formData.email}</strong>.
      </p>
      <p>
        <strong>Your Message:</strong>
        <br />
        {formData.note}
      </p>
      <p>
        If you have any urgent queries, please feel free to reply to this email.
      </p>
      <p style={{ marginTop: "30px", color: "#888" }}>
        Best regards,
        <br />
        The {companyName} Team
      </p>
    </div>
  );
};

export default ContactEmailTemplate;
