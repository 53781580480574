import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React from "react";
//import { useGoogleLogin } from "./GoogleLoginContext";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
//import { googleLogout } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { logout } from "../../store/slices/auth.slice";

interface LoggedInMenuProps {}

const LoggedInMenu: React.FC<LoggedInMenuProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { googleLoginState, setGoogleLoginState } = useGoogleLogin(); // Use the hook to access Google login state
  const { currentUserDetails } = useSelector((state: RootState) => state.auth);

  //const userDataString = googleLoginState.userData;

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    navigate("/myaccount/profile");
    setAnchorEl(null);
  };

  const handleLogoutSuccess = () => {
    setAnchorEl(null);
    // googleLogout();
    // setGoogleLoginState({
    //   isAuthenticated: false,
    // });
    dispatch(logout());
    localStorage.removeItem("userdata");
    localStorage.clear();
  };

  return (
    <>
      <Tooltip
        title={`Account of ${
          currentUserDetails?.user?.first_name || "Unknown"
        }`}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, textTransform: "uppercase" }}>
            {currentUserDetails?.user?.first_name?.charAt(0) || "U"}
            {currentUserDetails?.user?.last_name?.charAt(0) || "N"}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutSuccess}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default LoggedInMenu;
