import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SingleBedIcon from "@mui/icons-material/SingleBed";
import BathtubIcon from "@mui/icons-material/Bathtub";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchSingleProductData,
  filterLocation,
} from "../services/product.service";
import Carousel from "./Carousel";
import BidCard from "./BidCard";
import Breadcrumb from "../BreadCrumbs";
import "./ProductListcss.css";
import { Flag } from "@mui/icons-material";
import SimilarProducts from "./SimilarProducts";
import PriceInsightsTable from "./PriceInsightsTable";
import ReportDialog from "./Modal/ReportDialog";
import { useFetchBids } from "../services/bid.service";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CurrencyPrice from "../common/PriceWithCurrency";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function CustomTabPanel({ children, value, index, ...other }: TabPanelProps) {
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// type ReadmoreProps = {
//   children: React.ReactNode;
//   maxCharacterCount?: number;
// };

// function Readmore({ children, maxCharacterCount = 100 }: ReadmoreProps) {
//   const text = children as string;
//   const [isTruncated, setIsTruncated] = useState(true);
//   const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;

//   return (
//     <p className="text-left">
//       {resultString}
//       {text.length > maxCharacterCount && (
//         <span
//           style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
//           onClick={() => setIsTruncated(!isTruncated)}
//         >
//           {isTruncated ? "... Read More" : " Show Less"}
//         </span>
//       )}
//     </p>
//   );
// }

export default function ProductDetails() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [openReport, setOpenReport] = useState(false);
  const params = useParams();

  const {
    data: { product: data, similarProducts, priceInsightProducts } = {
      product: {},
      similarProducts: [],
      priceInsightProducts: [],
    },
    error,
    isLoading,
  } = useQuery(
    ["products", params.id],
    () => fetchSingleProductData(`${params.id}`, true),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: bids } = useFetchBids(params.id || "");

  const detailEntries: [string, string][] =
    data && data?.specifications ? Object.entries(data?.specifications) : [];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading product details.</div>;

  // const ColorButton = styled(Button)(({ theme }) => ({
  //   fontWeight: 600,
  //   fontSize: "1.2rem",
  //   marginTop: 20,
  //   width: "100%",
  //   backgroundColor: "blue",
  //   "&:hover": {
  //     backgroundColor: orange[800],
  //   },
  // }));

  // const bids = [
  //   { name: "Alice", emailId: "alice@gmail.com", amount: 83875 },
  //   { name: "Bob", emailId: "bob@gmail.com", amount: 80000 },
  //   { name: "Charlie", emailId: "charlie@gmail.com", amount: 50000 },
  //   { name: "David", emailId: "david@gmail.com", amount: 30000 },
  //   { name: "Eve", emailId: "eve@gmail.com", amount: 15000 },
  // ];

  const endTime = new Date(
    new Date().getTime() + 1 * 24 * 60 * 60 * 1000 + 20 * 60 * 60 * 1000
  ).toISOString();
  const sellerData = data?.seller?.id;
  console.log(data, bids);
  return (
    <Container sx={{ background: "white" }}>
      <Box
        position="static"
        sx={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "space-between",
          padding: theme.spacing(2),
          mt: 5,
          fontSize: "10px",
        }}
      >
        <Grid container>
          <Grid item xs={8} sm={6} md={8}>
            <Breadcrumb title={data?.title} />
          </Grid>
          <Grid item xs={4} sm={6} md={4} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              onClick={() => setOpenReport(true)}
              startIcon={<Flag />}
            >
              Report
            </Button>
          </Grid>
        </Grid>

        {/* <Button variant="outlined" startIcon={<Share />}>
          Share
        </Button> */}
      </Box>

      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Carousel images={data?.images || []} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            gutterBottom
            variant="h2"
            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {data?.title}
          </Typography>
          <Typography gutterBottom variant="h2" sx={{ fontSize: "1rem" }}>
            {data?.description?.slice(0, 200)}...
          </Typography>

          {data && data?.bidEndDate && (
            <BidCard product={data} bids={bids?.data} />
          )}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Typography gutterBottom variant="h6" sx={{ fontSize: "1.5rem" }}>
            <CurrencyPrice
              price={data?.price}
              currency={data?.currency || "USD"}
            />
          </Typography>
          {data?.category?.name == "Properties" && (
            <Grid display={"flex"}>
              <SingleBedIcon sx={{ width: "1.5rem" }} />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.bedrooms} beds
              </Typography>
              <BathtubIcon
                sx={{
                  width: "1.5rem",
                  marginLeft: "20px",
                }}
              />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.bathrooms} baths
              </Typography>
              <DirectionsCarFilledIcon
                sx={{
                  width: "1.5rem",
                  marginLeft: "20px",
                }}
              />
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: "1rem",
                  marginLeft: "6px",
                }}
              >
                {data?.specifications?.carParking} Car Parking
              </Typography>
            </Grid>
          )}

          <Typography
            gutterBottom
            variant="h6"
            sx={{
              fontSize: "1rem",
              borderBottom: "1px solid #d3d3d3",
              mr: "1rem",
            }}
          >
            <LocationOnIcon sx={{ width: "1rem" }} />{" "}
            {filterLocation(data?.location)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              width: "100%",
              background: "white",
              marginTop: "1.5rem",
              boxShadow: "0.8px 1px 3px 1px grey",
            }}
          >
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={3} sm={6} md={3}>
                  <img
                    src="https://www.pngall.com/wp-content/uploads/5/Profile-Avatar-PNG.png"
                    alt="Profile"
                    width={70}
                  />
                </Grid>
                <Grid item xs={9} sm={6} md={9}>
                  <Grid display={"flex"}>
                    <Grid item xs={4} sm={6} md={6}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        sx={{ fontSize: "1rem", marginLeft: "10px" }}
                      >
                        {data?.category?.name === "Properties"
                          ? data?.specifications?.listedBy
                          : "Seller"}
                      </Typography>
                    </Grid>
                    <Grid display={"flex"} item xs={8} sm={6} md={6}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        sx={{
                          fontSize: "1rem",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/sellerprofile", { state: { sellerData } })
                        }
                      >
                        View Profile
                      </Typography>

                      <NavigateNextIcon
                        sx={{ color: "blue", cursor: "pointer" }}
                        onClick={() =>
                          navigate("/sellerprofile", { state: { sellerData } })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    gutterBottom
                    variant="h6"
                    style={{ textTransform: "capitalize" }}
                    sx={{
                      fontSize: "1.2rem",
                      marginLeft: "10px",
                      color: "black",
                    }}
                  >
                    {data?.seller?.first_name} {data?.seller?.last_name}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ maxWidth: "100%", margin: "auto" }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 400, marginBottom: 1, color: "primary.main" }}
        >
          {data?.category?.name === "Properties"
            ? "Property Details"
            : " Product Details"}
        </Typography>

        <Divider sx={{ marginBottom: 4 }} />

        <Grid container spacing={2}>
          {detailEntries.length ? (
            detailEntries.map(([key, value], index) =>
              value ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={key}
                  sx={{
                    borderRight: index % 3 !== 2 ? "1px solid #ddd" : "none",
                    paddingRight: index % 3 !== 2 ? 2 : 0,
                    paddingBottom: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {`${key.replace(/([A-Z])/g, " $1").trim()}:`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {value}
                    </Typography>
                  </Box>
                </Grid>
              ) : null
            )
          ) : (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                No details found
              </Typography>
            </Box>
          )}
        </Grid>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
      <Typography
        variant="h5"
        sx={{ fontWeight: 400, marginBottom: 1, color: "primary.main" }}
      >
        More Information
      </Typography>
      <Typography gutterBottom variant="h2" sx={{ fontSize: "1rem" }}>
        {data?.description}
      </Typography>
      {priceInsightProducts?.length ? (
        <PriceInsightsTable priceInsightProducts={priceInsightProducts} />
      ) : null}
      {similarProducts?.length ? (
        <SimilarProducts similarProducts={similarProducts} />
      ) : null}
      {openReport && (
        <ReportDialog open={openReport} onClose={() => setOpenReport(false)} />
      )}
    </Container>
  );
}
