// Toaster.js
import React, { SyntheticEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import { hideToast } from "../../store/slices/toastSlice";
import { RootState } from "../../store/store";

const Toaster: React.FC = () => {
  const dispatch = useDispatch();
  const { open, message, severity, vertical, horizontal } = useSelector(
    (state: RootState) => state.toast
  );

  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideToast());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        onClose={handleClose as any}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
