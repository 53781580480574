import React, { ChangeEvent, MouseEvent } from "react";
import { Box, Slider, TextField, Typography } from "@mui/material";

type PriceRangeBoxProps = {
  onClose: () => void;
  price: { min: number; max: number };
  setPrice: (price: { min: number; max: number }) => void;
};

const PriceRangeBox: React.FC<PriceRangeBoxProps> = ({
  onClose,
  price,
  setPrice,
}) => {
  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setPrice({ min: newValue[0], max: newValue[1] });
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: "min" | "max"
  ) => {
    const value = event.target.value === "" ? 0 : Number(event.target.value);
    setPrice({ ...price, [key]: value });
  };

  const handleBlur = () => {
    if (price.min < 0) {
      setPrice({ min: 0, max: price.max });
    } else if (price.max > 10000) {
      setPrice({ min: price.min, max: 10000 });
    }
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Box sx={{ width: 300, padding: 2 }} onClick={handleClick}>
      <Typography id="price-range-slider" gutterBottom>
        Price Range
      </Typography>
      <Slider
        value={[price.min, price.max]}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        aria-labelledby="price-range-slider"
        min={0}
        max={10000}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <TextField
          label="Min Price"
          value={price.min}
          onChange={(e) => handleInputChange(e, "min")}
          onBlur={handleBlur}
          inputProps={{
            step: 10,
            min: 0,
            max: 10000,
            type: "number",
            "aria-labelledby": "price-range-slider",
          }}
        />
        <TextField
          label="Max Price"
          value={price.max}
          onChange={(e) => handleInputChange(e, "max")}
          onBlur={handleBlur}
          inputProps={{
            step: 10,
            min: 0,
            max: 10000,
            type: "number",
            "aria-labelledby": "price-range-slider",
          }}
        />
      </Box>
    </Box>
  );
};

export default PriceRangeBox;
