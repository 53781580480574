import React from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const Footer: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: theme.palette.secondary.main,
        color: "black",
        textAlign: "center",
      }}
    >
      <Typography variant="body1">
        © {new Date().getFullYear()} Bidz Bay. All rights reserved.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Link
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <Facebook />
          </IconButton>
        </Link>
        <Link
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <Twitter />
          </IconButton>
        </Link>
        <Link
          href="https://www.instagram.com/p/C9ouGhmpBLj/?igsh=MWZ2OHIxMjF4cXIzOQ=="
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <Instagram />
          </IconButton>
        </Link>
        <Link
          href="https://linkedin.com/company/bidz-bay"
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          <IconButton sx={{ color: theme.palette.text.primary }}>
            <LinkedIn />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
