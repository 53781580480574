// src/App.tsx
import React, { useEffect } from "react";
import { Container } from "@mui/material";
import BackgroundVideo from "./BackgroundVideo";
import ContentSection from "../ContentSection";
import videoSrc from "../../assets/BidzBay.mp4";
import AdviceCard from "./AdviceCard";
import ExclusiveDeals from "./ExclusiveDeals";
import PopularCategories from "./PopularCategories";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { clearFilters } from "../../store/slices/filter.slice";
import { useQuery } from "react-query";
import { fetchExclusiveProducts } from "../../store/slices/product.slice";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { refetch } = useQuery(
    "fetchExclusiveProducts",
    () => dispatch(fetchExclusiveProducts()),
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);
  
  useEffect(() => {
    dispatch(clearFilters())
  }, [dispatch]);
  
  return (
    <>
      <BackgroundVideo src={videoSrc} />
      <Container
        sx={{
          position: "relative",
          zIndex: 1,
          textAlign: "center",
          paddingTop: "10%",
          height: "70vh",
        }}
      >
        <ContentSection />
      </Container>
      <PopularCategories />
      <AdviceCard />
      <ExclusiveDeals />
      {/* <ContactUs /> */}
    </>
  );
};

export default Dashboard;
