import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ICategory,
  ISubCategory,
} from "../../components/category/category.type";
import fetchWrapper from "../../components/utils/fetchWrapper";

interface CategoryState {
  loading: boolean;
  category: ICategory[];
  categoryFilterOptions: {
    categories: [];
    subCategories: ISubCategory[];
  };
  error: string | null;
}

const initialState: CategoryState = {
  loading: false,
  category: [],
  categoryFilterOptions: {
    categories: [],
    subCategories: [],
  },
  error: null,
};

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    const categories = await fetchWrapper("categories");
    return categories.data as ICategory[];
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryFilterOptions(state) {
      (state.categoryFilterOptions.categories as any) =
        getCategoriesSelector(state);
    },
    setSubCategoryFilterOptions(state, action: PayloadAction<string>) {
      const categoryId = action.payload;
      // state.categoryFilterOptions.categories = getSubCategoriesSelector(state, categoryId);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchCategory.fulfilled,
        (state, action: PayloadAction<ICategory[]>) => {
          state.loading = false;
          state.category = action.payload;
        }
      )
      .addCase(fetchCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const getCategoriesSelector = (state: CategoryState) => {
  return state?.category?.map((category: ICategory) => ({
    ...category,
    value: category.uuid,
    label: category.displayName,
  }));
};

export const getSubCategoriesSelector = (
  state: CategoryState,
  selectedCategoryId: string
) => {
  const getSubCategory = state.category.find(
    (categoryItem: ICategory) => categoryItem.uuid === selectedCategoryId
  );
  return (
    getSubCategory?.subcategories.map((subcategory: ISubCategory) => ({
      ...subcategory,
      value: subcategory.uuid,
      label: subcategory.displayName,
    })) || []
  );
};

export const getCategoryTags = (state: CategoryState) => {
  return state.category.filter((item) => item.tags.includes("popular"));
};

export default categorySlice.reducer;
