import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import CustomTheme from "../../theme";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getCategoriesSelector } from "../../store/slices/category.slice";
import { ICategory } from "./category.type";
import { useNavigate, useLocation } from "react-router-dom";
import { updateNestedValue } from "../../components/utils/helpers";
import { setFilters, clearFilters } from "../../store/slices/filter.slice";
import { fetchProducts } from "../../store/slices/product.slice";

const animate = keyframes`
  50% {
    box-shadow: 0 1px 3px;
  }
`;


const CategoryAll = () => {
  const theme = useTheme<typeof CustomTheme>();
  const dispatch = useDispatch<AppDispatch>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));  
  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state.category)
  );
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = (categoryItem: ICategory) => {
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      "category.uuid",
      categoryItem.uuid
    );
    dispatch(setFilters(updatedValues));
    if (location.pathname.includes("/products")) {
      dispatch(fetchProducts());
    }
    navigate(`products`);
  };

  const allCategoryClick = () => {
    dispatch(clearFilters());
    if (location.pathname.includes("/products")) {
      dispatch(fetchProducts());
    }
    navigate(`products`);
  }

  return (
    <Grid>
      {(isBigScreen || isMediumScreen)&&  <Grid
      container
      xs={12}
      sx={{
        position: "absolute",
        top: "63px",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark,
        padding: "0",
        width: "100%",
        
        zIndex: 100,
        textAlign: "center",
        animation: `${animate} 10s infinite`,
      }}
    >
      <Stack spacing={theme.spacing(1)} direction="row" sx={{ m: "auto 30px",paddingTop:"4px",paddingBottom:"4px" }}>
        <Button variant="text" onClick={allCategoryClick}>All Categories</Button>
        {categories.map((categoryItem: ICategory) => (
          <>
            <Button onClick={() => handleOnClick(categoryItem)} variant="text">
              {categoryItem.displayName}
            </Button>
          </>
        ))}
      </Stack>
      </Grid>}
      {isSmallScreen &&  <Grid
      container
      xs={12}
      sx={{
        position: "absolute",
        top: "63px",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.dark,
        padding: "0",
        width: "100%",
        zIndex: 100,
        textAlign: "center",
        animation: `${animate} 10s infinite`,
      }}
    >
      <Stack spacing={theme.spacing(1)} direction="column" sx={{ m: "auto 30px",paddingTop:"4px",paddingBottom:"4px" }}>
        <Button variant="text" onClick={allCategoryClick}>All Categories</Button>
        {categories.map((categoryItem: ICategory) => (
          <>
            <Button onClick={() => handleOnClick(categoryItem)} variant="text">
              {categoryItem.displayName}
            </Button>
          </>
        ))}
      </Stack>
      </Grid>}
    </Grid>

   
  );
};

export default CategoryAll;
