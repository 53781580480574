// Breadcrumbs.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

const Breadcrumb: React.FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs>
      {pathnames.length > 0 && (
        <Link style={{ color: theme.palette.text.secondary }} to="/">
          Home
        </Link>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography color="text.secondary">{title ? title : name}</Typography>
        ) : (
          <Link to={routeTo}>{name}</Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
