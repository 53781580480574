import React, { useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import CustomTheme from "../../theme";
import { useTheme } from "@mui/material/styles";
import Breadcrumb from "../BreadCrumbs";
import { ProductType } from "./product.type";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchProducts } from "../../store/slices/product.slice";
import Loader from "../Loader";
import { useQuery } from "react-query";
import SubProductFilters from "./SubFilters/SubFilters";
import SubProductFiltersMobile from "./SubFilters/MobileSubFilter";

import PriceSort from "./SubFilters/PriceSort";
import { updateNestedValue } from "../../components/utils/helpers";
import { setFilters } from "../../store/slices/filter.slice";
import noProductsImage from "../../assets/no-products.webp";

const ProductsDashboard: React.FC = () => {
  const theme = useTheme<typeof CustomTheme>();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<AppDispatch>();
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );
  const { products } = useSelector((state: RootState) => state.products);

  const { error, isLoading, refetch } = useQuery(
    "fetchProducts",
    () => dispatch(fetchProducts()),
    { enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [selectedFilters.sortBy, refetch]);

  const addToCart = (product: ProductType) => {
    console.log("product", product);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      name,
      value
    );
    dispatch(setFilters(updatedValues));
  };

  return (
    <Container>
      {(isBigScreen || isMediumScreen) && <SubProductFilters />}

      {(isBigScreen || isMediumScreen) && (
        <Box
          sx={{ padding: theme.spacing(2), background: "white", zIndex: "10" }}
        >
          <Grid display={"flex"} container spacing={1}>
            <Grid item xs={9}>
              <Breadcrumb />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      )}

      {isSmallScreen && (
        <Box
          position="sticky"
          top="0"
          sx={{ padding: theme.spacing(2), background: "white", zIndex: "10" }}
        >
          <Grid display={"flex"} container spacing={1}>
            <Grid item xs={9}>
              <Breadcrumb />
            </Grid>
            <Grid item xs={3}>
              <SubProductFiltersMobile />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
        {!isLoading && !error && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h4" gutterBottom>
                  Products
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <PriceSort
                  name="sortBy"
                  sortBy={selectedFilters.sortBy}
                  handleSortChange={handleSortChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {products && products.length > 0 ? (
                products?.map((product: any) => (
                  <Grid item xs={12} sm={6} md={4} key={product?.id}>
                    <Product product={product} onAddToCart={() => addToCart} />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <img
                      src={noProductsImage}
                      alt="No products available"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                    <Typography variant="h6" color="textSecondary" mt={2}>
                      No products available at the moment.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {isLoading && <Loader />}
      </Box>
    </Container>
  );
};

export default ProductsDashboard;
