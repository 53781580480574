import React, { ChangeEvent } from "react";
import {
  Box,
  TextField,
  IconButton,
  MenuItem,
  Select,
  InputAdornment,
  FormControl,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getCategoriesSelector } from "../store/slices/category.slice";
import { ICategory } from "./products/product.type";
import { useNavigate } from "react-router-dom";
import { updateNestedValue } from "./utils/helpers";
import { setFilters } from "../store/slices/filter.slice";

const SearchBox: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const categories = useSelector((state: RootState) =>
    getCategoriesSelector(state.category)
  );
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );

  const handleSearch = () => {
    navigate(`products`);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      name,
      value
    );
    dispatch(setFilters(updatedValues));
  };

  const handleInputOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.target;
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      name,
      value
    );
    dispatch(setFilters(updatedValues));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
        mb: 2,
        border: "none",
      }}
    >
      <TextField
        style={{ minWidth: isSmallScreen ? "auto" : 520 }}
        variant="outlined"
        placeholder="Search for properties, mobiles and more..."
        value={selectedFilters.search}
        name="search"
        onChange={handleInputOnChange}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <FormControl
                variant="standard"
                sx={{ minWidth: 120, display: isSmallScreen ? "none" : "" }}
              >
                <Select
                  disableUnderline={true}
                  value={selectedFilters.category.uuid || "All Categories"}
                  onChange={handleSelectChange}
                  sx={{
                    bgcolor: "white",
                    color: "black",
                    borderRadius: "2.5rem",
                    "& .MuiSelect-select": {
                      padding: "10px",
                      color: "black",
                    },
                    "& .MuiSelect-icon": {
                      color: "black",
                    },
                  }}
                  name="category.uuid"
                >
                  <MenuItem value="All Categories">All Categories</MenuItem>
                  {categories.map((category: ICategory) => (
                    <MenuItem key={category.uuid} value={category.uuid}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputAdornment>
          ),
        }}
        sx={{
          width: "350px",
          bgcolor: "white",
          color: "black",
          borderRadius: "2.5rem",
          mr: "10px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: 0,
            },
            "&:hover fieldset": {
              borderWidth: 0,
            },
            "&.Mui-focused fieldset": {
              borderWidth: 0,
            },
          },
          "& .MuiInputBase-input": {
            color: "black",
          },
        }}
      />
      <IconButton
        color="primary"
        onClick={handleSearch}
        sx={{
          backgroundColor: "primary.main",
          color: "white",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBox;
