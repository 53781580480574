import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Home from "../../assets/home.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getCategoryTags } from "../../store/slices/category.slice";
import { ICategory } from "../../components/products/product.type";
import { updateNestedValue } from "../../components/utils/helpers";
import { setFilters } from "../../store/slices/filter.slice";
import Laptop from "../../assets/laptop.jpg";

const PopularCategories = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const categoriesTags = useSelector((state: RootState) =>
    getCategoryTags(state.category)
  );
  const { filters: selectedFilters } = useSelector(
    (state: RootState) => state.filter
  );

  const handleOnClickPopularCategory = (category: ICategory) => {
    const updatedValues = updateNestedValue(
      { ...selectedFilters },
      "category.uuid",
      category.uuid
    );
    dispatch(setFilters(updatedValues));
    navigate("/products");
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Explore Popular Categories
      </Typography>
      <Grid container spacing={4}>
        {categoriesTags.map((category: ICategory) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={category.name}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: 200,
                height: 200,
                borderRadius: "50%",
                overflow: "hidden",
                marginBottom: 2,
                boxShadow: 3,
                cursor: "pointer",
              }}
              onClick={() => handleOnClickPopularCategory(category)}
            >
              <img
                src={category.name.includes("Properties") ? Home : Laptop}
                alt={category.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography variant="subtitle1">{category.name}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PopularCategories;
