import React, { useEffect, useState } from "react";
import { Select, FormControl, MenuItem } from "@mui/material";
import PriceRangeBox from "./PriceRangeBox";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setFilters } from "../../store/slices/filter.slice";

type DropdownWithPriceRangeProps = {
  price: { min: number, max: number };
  setPrice: (price: { min: number; max: number }) => void;
}
const DropdownWithPriceRange: React.FC<DropdownWithPriceRangeProps> = ({
  price, setPrice
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (price.max > 0) {
      dispatch(
        setFilters({
          price: [price.min, price.max].toString(),
        })
      );
    }
  }, [price, dispatch]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: "150px" }} size="small">
      <Select
        labelId="select-label"
        open={open}
        onClose={handleClose}
        onOpen={handleClick}
        value={price}
        renderValue={() =>
          price.min > 0 || price.max > 0
            ? `$ ${price.min} - $ ${price.max}`
            : "Select Price Range"
        }
      >
        <MenuItem value={`${price.min}-${price.max}`}>
          <PriceRangeBox
            onClose={handleClose}
            price={price}
            setPrice={setPrice}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default DropdownWithPriceRange;
