import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import { fetchCurrencyRates } from "../store/slices/currency.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { COUNTRY_CURRENCY } from "./utils/constants";

const CurrencyConverter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { rates, status } = useSelector((state: RootState) => state.currency);
  const location = useSelector((state: RootState) => state.location.location);
  const [selectedCurrency, setSelectedCurrency] = useState(
    COUNTRY_CURRENCY[location.country as keyof typeof COUNTRY_CURRENCY] || "USD"
  );

  useEffect(() => {
    dispatch(fetchCurrencyRates());
  }, [dispatch]);

  useEffect(() => {
    if (location.country) {
      setSelectedCurrency(
        COUNTRY_CURRENCY[location.country as keyof typeof COUNTRY_CURRENCY]
      );
    }
  }, [location]);

  // const handleCurrencyChange = (event: SelectChangeEvent) => {
  //   const newCurrency = event.target.value;
  //   setSelectedCurrency(newCurrency);
  // };
  // console.log(
  //   "rates",
  //   rates,
  //   location.country,
  //   COUNTRY_CURRENCY[location.country as keyof typeof COUNTRY_CURRENCY],
  //   selectedCurrency
  // );
  return (
    <div>
      {/* <FormControl fullWidth variant="standard">
        {status === "loading" && <CircularProgress />}

        {status === "succeeded" && (
          <Select
            labelId="currency-label"
            id="currency"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            label="Select Currency"
            sx={{ color: "text.tertiary" }}
          >
            {Object.entries(rates).map(([currency], index) => (
              <MenuItem
                value={currency.toUpperCase()}
                key={`${currency}-${index}`}
              >
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl> */}
    </div>
  );
};

export default CurrencyConverter;
