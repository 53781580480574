import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import GavelIcon from "@mui/icons-material/Gavel";
import GradingIcon from "@mui/icons-material/Grading";
import CheckIcon from "@mui/icons-material/Check";
import { keyframes } from "@emotion/react";
import ConfirmDialog from "./ConfirmDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ProductType } from "./product.type";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import BidDialog from "./Bid/BidDialog";
import { useDispatch } from "react-redux";
//import { useGoogleLogin } from "../../components/auth/GoogleLoginContext";
import { setLoginModelVisibility } from "../../store/slices/auth.slice";
import {
  useAcceptBid,
  usePlaceBid,
  useUpdateBid,
} from "../services/bid.service";
import BiddingTimer from "./Bid/BidTimer";
import { COUNTRY_CURRENCY } from "../utils/constants";
import CurrencyPrice from "../common/PriceWithCurrency";

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: boolean;
  is_exclusive: boolean;
  phone_number: string;
  location: {
    city: string;
    state: string;
    country: string;
  };
}

export interface Bid {
  productId: string;
  currency: string;
  amount: number;
  buyer: User;
  isAccepted: boolean;
  uuid: string;
}

interface BidCardProps {
  product: ProductType;
  bids: Bid[];
}

const animate = keyframes`
  50% {
    box-shadow: 1px 15px 20px;
  }
`;

const BidCard: React.FC<BidCardProps> = React.memo(({ bids, product }) => {
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBid, setSelectedBid] = useState<Bid | null>(null);
  const [myBid, setMyBid] = useState<Bid | null>(null);
  const [bidClosed, setBidClosed] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);

  const [openBidDialog, setOpenBidDialog] = useState(false);

  const { mutate: acceptBid } = useAcceptBid();

  useEffect(() => {
    const myBid = bids?.find((bid) => bid.buyer.email === user?.email) || null;
    setMyBid(myBid);
    const isBidClosed = bids?.find((bid) => bid.isAccepted);
    setBidClosed(isBidClosed ? true : false);
  }, [bids, user?.email]);

  const handleAcceptClick = (bid: Bid) => {
    setSelectedBid(bid);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    if (selectedBid) {
      acceptBid({ bidId: selectedBid.uuid, productId: product.uuid });
    }
    setDialogOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlaceBid = () => {
    if (user.isUserLoggedIn && user?.email) {
      setOpenBidDialog(true);
    } else {
      dispatch(setLoginModelVisibility({ showLogin: true }));
    }
  };

  const location = useSelector((state: RootState) => state.location.location);

  const [bidAmount, setBidAmount] = React.useState(0);
  const [currency, setCurrency] = React.useState("USD");
  const [error, setError] = useState<string>("");
  const MINIMUM_AMOUNT = product.price;

  const { mutate: placeBid } = usePlaceBid();
  const { mutate: updateBid } = useUpdateBid();

  React.useEffect(() => {
    if (location) {
      setCurrency(
        COUNTRY_CURRENCY[
          (location?.country as keyof typeof COUNTRY_CURRENCY) || "USD"
        ]
      );
    }
  }, [location]);

  const handleAmountChange = (event: { target: { value: any } }) => {
    setError("");
    setBidAmount(Number(event.target.value));
  };

  const handleCurrencyChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCurrency(event.target?.value);
  };

  const handleSubmit = () => {
    if (bidAmount < MINIMUM_AMOUNT) {
      setError(`The minimum bid amount is ₹${MINIMUM_AMOUNT}`);
    } else {
      setError("");
      myBid && myBid.uuid
        ? updateBid({ bidId: myBid?.uuid || "", amount: bidAmount, currency })
        : placeBid({
            productId: product.uuid || "",
            amount: bidAmount,
            currency,
          });
      setOpenBidDialog(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          background: "white",
          marginTop: "1.5rem",
          boxShadow: "0.8px 1px 3px 1px grey",
        }}
      >
        <CardContent>
          {!bidClosed && product?.bidEndDate && (
            <BiddingTimer
              bidEndDate={new Date(product.bidEndDate)}
              isBidClosed={bidClosed}
            />
          )}

          <Grid container sx={{ marginTop: "2vh" }}>
            <Grid item xs={6} sm={7} md={7}>
              <Typography
                gutterBottom
                variant="h6"
                sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Bid End Date:{" "}
              </Typography>

              <Typography
                gutterBottom
                variant="h6"
                sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
              >
                Starting Bidding Amount:{" "}
              </Typography>

              {bids && bids.length > 0 && (
                <>
                  <Typography
                    gutterBottom
                    variant="h6"
                    sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    Top Bidding Amount:{" "}
                  </Typography>
                  {myBid && (
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                    >
                      My Amount:{" "}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={6} sm={5} md={5}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontSize: "0.8rem", color: "gray" }}
              >
                {product?.bidEndDate
                  ? new Date(product.bidEndDate).toLocaleDateString("en-US", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  : "NA"}
              </Typography>

              <Typography
                gutterBottom
                display="inline-flex"
                alignItems="center"
                sx={{ fontSize: "0.8rem", color: "grey" }}
              >
                <CurrencyPrice
                  price={product?.price}
                  currency={product?.currency || "USD"}
                />
              </Typography>

              {bids && bids.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: "0.8rem", color: "gray" }}
                  >
                    <CurrencyPrice
                      price={bids[0]?.amount}
                      currency={bids[0]?.currency || "USD"}
                    />
                  </Typography>
                  {myBid && (
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontSize: "0.8rem", color: "gray" }}
                    >
                      <CurrencyPrice
                        price={myBid?.amount}
                        currency={myBid.currency || "USD"}
                      />
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {bids && bids.length > 0 && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ width: "100%" }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={handleClose}
                sx={{ display: "block", width: "100%", p: 2, mr: 5 }}
              >
                {bids.map((bid, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{ alignItems: "center", marginBottom: "0.5rem" }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="body1" sx={{ color: "black" }}>
                        {bid?.buyer?.first_name} {bid?.buyer?.last_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" sx={{ color: "gray" }}>
                        <span>&#8377;</span> {bid.amount}
                      </Typography>
                    </Grid>
                    {product?.seller?.email === user?.email && (
                      <Grid item xs={2}>
                        <IconButton
                          color="primary"
                          onClick={() => handleAcceptClick(bid)}
                          title="Accept bid"
                        >
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </MenuItem>
            </Menu>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Ensures spacing between buttons
              flexWrap: "wrap", // Ensures buttons wrap if they overflow
              maxWidth: "100%", // Ensure the container doesn't overflow
            }}
          >
            {product?.seller?.email !== user?.email && !bidClosed && (
              <Button
                fullWidth={!bids || bids?.length === 0}
                startIcon={<GavelIcon />}
                variant="contained"
                color="primary"
                onClick={handlePlaceBid}
                sx={{
                  animation: `${animate} 2s infinite`,
                  marginTop: "2vh",
                  flexGrow: 1, // Allows the button to grow and take up space
                  maxWidth: bids && bids.length > 0 ? "48%" : "100%", // Max width to ensure they align properly
                }}
                autoFocus
              >
                Place Bid
              </Button>
            )}
            {bids && bids.length > 0 && !bidClosed && (
              <Button
                startIcon={<GradingIcon />}
                variant="outlined"
                onClick={handleClick}
                sx={{
                  marginLeft: "5px",
                  fontWeight: "bold",
                  marginTop: "2vh",
                  flexGrow: 1, // Allows this button to grow as well
                  maxWidth: "48%", // Max width to ensure alignment with the other button
                }}
              >
                View Bids ({bids.length})
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={dialogOpen}
        title="Confirm Accept Bid"
        description={`Are you sure you want to accept the bid from ${
          selectedBid?.buyer?.first_name
        } ${
          selectedBid?.buyer?.last_name ? selectedBid?.buyer?.last_name : ""
        } for ${currency}${selectedBid?.amount}?`}
        onCancel={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <BidDialog
        open={openBidDialog}
        myBid={myBid}
        product={product}
        onClose={() => setOpenBidDialog(false)}
        topBid={bids && bids[0]?.amount ? bids[0]?.amount : 0}
        currency={currency}
        error={error}
        handleAmountChange={handleAmountChange}
        setCurrency={handleCurrencyChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
});

export default BidCard;
