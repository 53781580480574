import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import ReportEmailTemplate from "./ReportEmailTemplate";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useState } from "react";

interface BidModalProps {
  open: boolean;
  onClose: () => void;
}

const initialState = {
  userType: "",
  reason: "",
  description: "",
};

const ReportDialog: React.FC<BidModalProps> = ({ open, onClose }) => {
  const [formData, setFormData] = useState(initialState);
  const { currentUserDetails } = useSelector((state: RootState) => state.auth);
  const validate = () => {
    let valid = true;
    let errorsTemp = { userType: "", reason: "", description: "" };

    if (!formData.userType.trim()) {
      errorsTemp.userType = "User Type is required";
      valid = false;
    }

    if (!formData.reason.trim()) {
      errorsTemp.reason = "Reason is required";
      valid = false;
    }

    if (!formData.description.trim()) {
      errorsTemp.description = "Description is required";
      valid = false;
    }

    //setErrors(errorsTemp);
    return valid;
  };

  const handleSubmit = async () => {
    if (validate()) {
      const emailContent = ReactDOMServer.renderToString(
        <ReportEmailTemplate
          companyName="BidzBay"
          formData={formData}
          userData={currentUserDetails.user}
          productLink={`${window.location.origin}${window.location.pathname}`}
        />
      );

      try {
        await sendEmail(
          currentUserDetails.user.email,
          "Thank you for contacting us",
          emailContent
        );
        console.log("Email sent successfully");
      } catch (error) {
        console.error("Error sending email:", error);
      }
      //setFormData(initialState);
      onClose();
      // console.log("Form data:", formData);
    }
  };

  const sendEmail = async (
    to: string,
    subject: string,
    htmlContent: string
  ) => {
    return await fetch(`${process.env.REACT_APP_API_BASE_URL_USER}mail/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: `${to}, ${process.env.REACT_APP_CONTACT_EMAIL}`,
        subject: "Thank you for reporting product in BidzBay",
        template: htmlContent,
      }),
    });
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="report-dialog-title"
      aria-describedby="report-dialog-description"
    >
      <DialogTitle id="report-dialog-title">Report an Issue</DialogTitle>
      <DialogContent>
        {/* User Type Selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="user-type-label">Select User Type</InputLabel>
          <Select
            labelId="user-type-label"
            id="user-type-select"
            label="I am a"
            name="userType"
            onChange={handleChange}
          >
            <MenuItem value="potential_buyer">I am Potential Buyer</MenuItem>
            <MenuItem value="landlord">I am Landlord</MenuItem>
            {/* Add more user types if needed */}
          </Select>
        </FormControl>

        {/* Reason for Report */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="report-reason-label">Reason for Report</InputLabel>
          <Select
            labelId="report-reason-label"
            id="report-reason-select"
            label="Reason for Report"
            name="reason"
            onChange={handleChange}
          >
            <MenuItem value="property_not_available">Property not available</MenuItem>
            <MenuItem value="no_property_details">No property details</MenuItem>
            <MenuItem value="poor_quality_or_irrelevant_photos">Poor quality or irrelevant photos</MenuItem>
            <MenuItem value="inaccurate_location">Inaccurate location</MenuItem>
            <MenuItem value="inaccurate_number_of_bedrooms">Inaccurate number of bedrooms</MenuItem>
            <MenuItem value="incorrect_property_type">Incorrect property type</MenuItem>
            <MenuItem value="inaccurate_price">Inaccurate price</MenuItem>
            <MenuItem value="scam">Scam or Fraud</MenuItem>
            <MenuItem value="other">Other</MenuItem>
            {/* Add more reasons if needed */}
          </Select>
        </FormControl>

        {/* Description */}
        <TextField
          id="report-description"
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          name="description"
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
