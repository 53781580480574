import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export type IOptions = {
  label: string;
  value: string;
};

type AutoCompleteSearchBoxProps = {
  options: IOptions[];
  limitTags?: number;
  label?: string;
  multiple?: boolean;
  value?: IOptions | IOptions[] | null;
  handleChange?: (value: IOptions | IOptions[] | null) => void;
  required?: boolean;
  fullWidth?: boolean;
  errors?: {
    countryCode: string;
  };
};

const AutoCompleteSearchBox: React.FC<AutoCompleteSearchBoxProps> = ({
  options,
  limitTags = 2,
  label = "Search",
  multiple = true,
  value = null,
  handleChange,
  required = false,
  fullWidth = false,
  errors = {
    countryCode: "",
  },
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      id="multiple-limit-tags"
      onChange={(event, newValue) => {
        if (handleChange) {
          handleChange(newValue);
        }
      }}
      options={options}
      getOptionLabel={(option: IOptions) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          placeholder="Search"
          error={!!errors.countryCode}
          helperText={errors.countryCode}
        />
      )}
      value={value}
      sx={{
        maxWidth: "350px",
        minWidth: "200px",
        width: fullWidth ? "300px" : "auto",
      }}
    />
  );
};

export default AutoCompleteSearchBox;
