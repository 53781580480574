import React from "react";

interface ReportEmailTemplateProps {
  companyName: string;
  userData: {
    first_name: string;
    email: string;
  };
  formData: {
    userType: string;
    reason: string;
    description: string;
  };
  productLink: string;
}

const userTypes = {
  potential_buyer: "Potential Buyer",
  landlord: "Landlord",
};

const reasons = {
  incorrect_info: "Incorrect Information",
  scam: "Scam or Fraud",
  other: "Other",
};

const ReportEmailTemplate: React.FC<ReportEmailTemplateProps> = ({
  companyName = "BidzBay",
  userData,
  formData,
  productLink,
}) => {
  return (
    <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
      <h2 style={{ color: "#333" }}>Thank you, {userData.first_name}!</h2>
      <p>
        We appreciate you reaching out to {companyName}. Our team will try to
        resolve the issue.
      </p>
      <p>
        <strong>Product Link:</strong>
        <br />
        <a href={productLink} target="_blank" rel="noopener noreferrer">
          {productLink}
        </a>
      </p>
      <p>
        <strong>User Type:</strong>
        <br />
        {userTypes[formData.userType as keyof typeof userTypes]}
      </p>
      <p>
        <strong>Reason:</strong>
        <br />
        {reasons[formData.reason as keyof typeof reasons]}
      </p>
      <p>
        <strong>Your Message:</strong>
        <br />
        {formData.description}
      </p>
      <p>We will contact the seller and try to fix this asap.</p>
      <p>
        If you have any urgent queries, please feel free to reply to this email.
      </p>
      <p style={{ marginTop: "30px", color: "#888" }}>
        Best regards,
        <br />
        The {companyName} Team
      </p>
    </div>
  );
};

export default ReportEmailTemplate;
